import React, { useRef, useEffect, useState } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import Slider from "react-slick"
import { graphql, useStaticQuery } from "gatsby"

import { Grid, useTheme, makeStyles, useMediaQuery } from "@material-ui/core"

import { PartnerSliderItem } from "../PartnerSliderItem"
import { gql } from "@apollo/client"
import { useQuery } from "@apollo/client"

gsap.registerPlugin(ScrollTrigger)

const PortfolioSlide = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [clientsData, setClientsData] = useState()

  const isTablet = useMediaQuery(
    theme.breakpoints.between("(min-width:600.99px)", "(max-width:900px)")
  )
  const isMobile = useMediaQuery("(max-width:600px)")
  let slideItems

  if (isTablet) {
    slideItems = 3
  } else if (isMobile) {
    slideItems = 1
  } else {
    slideItems = 4
  }

  const partners_settings = {
    dots: true,
    infinite: true,
    slidesToShow: slideItems,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    swipeToSlide: true,
  }

  const APOLLO_QUERY = gql`
  query ExampleQuery($queryTeamContentsOrderby: String) {
    queryPartnersContents(orderby: $queryTeamContentsOrderby) {
      id
      data {
        name {
          iv
        }
        image {
          iv {
            url
            thumbnailUrl
          }
        }
      }
    }
  }
  
`
const { loading, error, data } = useQuery(APOLLO_QUERY, {
    variables: { queryTeamContentsOrderby: "created desc" },
  })

useEffect(() => {
  if (!loading) {
    setClientsData(
      data &&
        data.queryPartnersContents.map(item => ({
          id: item.id,
          image: item.data && item.data.image.iv && item.data.image.iv[0].url,
          name: item.data && item.data.name && item.data.name.iv,
          position: item.data && item.data.position && item.data.position.iv,
        }))
    )
  }
}, [loading])

  return (
    <Grid container className={classes.sectionPartnersGrid}>
      <Grid item sm={12} style={{ maxWidth: "100%", width: "100%" }}>
        <Slider
          {...partners_settings}
          className={`${classes.directionLtr} ${classes.partnersCarousell} ${classes.sliderStyle}`}
        >
          {clientsData && clientsData.map((item, index) => (
            <SliderItem key={index} slideItem={item} />
          ))}
        </Slider>
      </Grid>
    </Grid>
  )
}
export { PortfolioSlide }

const useStyles = makeStyles(theme => ({
  directionLtr: {
    flip: false,
  },
  sliderStyle: {
    marginTop: 36,
    marginBottom: "1rem",
    maxWidth: "100vw",
    "& .slick-arrow": {
      display: "none !important",
    },
  },
  partnersCarousell: {
    marginBottom: "2rem",
    "& .slick-dots li button:before": {
      color: "#00a69c",
      fontSize: 12,
    },
    "& .slick-dots": {
      marginBottom: -16,
    },
  },
}))

// const SlideItem = ({ children }) => {
//   const revealRef = useRef(null)

//   useEffect(() => {
//     gsap.from(revealRef.current, {
//       scrollTrigger: revealRef.current,
//       y: 100,
//       delay: 0.4,
//       opacity: 0,
//       duration: 1,
//       stagger: 0.5,
//       rotateX: -60,
//     })
//   }, [])

//   return <div ref={revealRef}>{children}</div>
// }

const SliderItem = ({ slideItem }) => {
  const revealRef = useRef(null)

  useEffect(() => {
    gsap.from(revealRef.current, {
      scrollTrigger: revealRef.current,
      y: 100,
      // y: -50,
      delay: 0.4,
      opacity: 0,
      duration: 1,
      stagger: 0.5,
      ease: "elastic.out(0.2, 0.1)",
    })
  }, [])

  return <div ref={revealRef}><PartnerSliderItem slideItem={slideItem} /></div>
}
