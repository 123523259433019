import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import { Timeline } from "gsap/all"
import { Typography, makeStyles } from "@material-ui/core"
// import { CollectionsOutlined } from "@material-ui/icons"

// This component for partner Carousell

gsap.registerPlugin(ScrollTrigger)

const PartnerSliderItem = ({ slideItem }) => {
  const useStyles2 = makeStyles(theme => ({
    title: {
      textAlign: "center",
      marginTop: 24,
      fontSize: 20,
      color: "#999",
      textTransform: "uppercase",
    },
    image: {
      width: 180,
      height: 180,
      textAlign: "center",
      margin: "0 auto",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      // backgroundImage: `url(${process.env.NEXT_PUBLIC_API_URL}${slideItem.Image[0].formats.thumbnail.url})`,
      // backgroundImage: `url(${slideItem.featuredImage.node.localFile.childrenImageSharp[0].fixed.src})`
      backgroundImage: `url(${slideItem.image})`,
    },
  }))
  const classes = useStyles2()

  const imageRef = useRef(null)
  const titleRef = useRef(null)

  useEffect(() => {
    gsap.from(imageRef.current, {
      scrollTrigger: imageRef.current,
      x: 100,
      // y: -50,
      delay: 0.4,
      opacity: 0,
      duration: 2,
      stagger: 0.5,
      ease: "elastic.out(0.2, 0.1)",
    })
    gsap.from(titleRef.current, {
      scrollTrigger: titleRef.current,
      x: -100,
      // y: -50,
      delay: 0.4,
      opacity: 0,
      duration: 2,
      stagger: 0.5,
      ease: "elastic.out(0.2, 0.1)",
    })
  }, [])

  return (
    <>
      <div className={classes.image} ref={imageRef}></div>
      <Typography className={classes.title} ref={titleRef}>
        {slideItem.name}
      </Typography>
    </>
  )
}
export { PartnerSliderItem }
