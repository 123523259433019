import React, { useEffect, useState, useRef } from "react"
import {
  Grid,
  Hidden,
  Container,
  makeStyles,
  CssBaseline,
  CircularProgress,
} from "@material-ui/core"

import { SnackbarProvider } from "notistack"

import SEO from "../components/seo"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import WhoWeAre from "../components/home/who-we-are"
import BodyBg from "../components/home/body-bg"

import titleLine from "../../static/images/title-line.png"

import {
  OurTeam,
  OurClints,
  OurPartners,
  AnimatedText,
  SvgComponent,
  ContactUs,
} from "../components/home"
import Footer from "../components/footer"
import Header from "../components/header/headerHome"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const IndexPage = () => {
  const classes = styles()
  const goToTopRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [contactFormToggle, setContactFormToggle] = useState(false)

  let loader = ""

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 600)
  }, [])

  if (windowGlobal) {
    if (
      windowGlobal.matchMedia("(display-mode: standalone)").matches ||
      windowGlobal.matchMedia("(display-mode: fullscreen)").matches
    ) {
      documentGlobal.getElementsByClassName("preloader").className =
        "preloaderHide"
    }
  }

  // async function fetchData() {
  //   // await fetch("https://cms.trustit-co.com/api/content/arafasapps/messages/")
  //   //   .then(res => {
  //   //     return res.json();
  //   //     })
  //   //   .catch(err => {return res.json(err)})

  //   fetch("https://cms.trustit-co.com/api/content/arafasapps/messages")
  //     .then(data => {
  //       return data.json()
  //     }).then(res => {
  //       console.log(res)
  //     })
  //     // .then(post => {
  //     //   console.log(post.title)
  //     // })
  // }

  // useEffect(() => {
  //   fetchData()
  // }, [])

  return (
    <>
      <SnackbarProvider maxSnack={3}>
        <SEO title={"Home"} />
        <CssBaseline />
        {loading && (
          <div
            className={`${classes.preloader} preloader`}
            style={{ display: loader }}
          >
            <CircularProgress
              className={`${classes.drawerTextLogo} ${classes.preloaderTextLogo}`}
            />
          </div>
        )}
        {!loading && (
          <>
            <Header
              setContactFormToggle={setContactFormToggle}
              contactFormToggle={contactFormToggle}
            />
            <div id="#top" ref={goToTopRef} />
            <Container
              className={`${classes.fullWidthContainer} ${classes.bodyBg}`}
              maxWidth={false}
              style={{ display: loading ? "none" : "block" }}
            >
              <Container
                maxWidth={false}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <Grid container>
                  <Grid item xs={6} className={classes.heroText}>
                    <AnimatedText />
                  </Grid>
                  <Grid item xs={6}>
                    <SvgComponent />
                  </Grid>
                </Grid>
              </Container>
              <WhoWeAre />
              <OurPartners />
              <OurTeam />
              <OurClints />
              <ContactUs />
              <Footer
                contactFormStatus={contactFormToggle}
                setContactFormToggle={setContactFormToggle}
              />
            </Container>
            <Hidden mdDown>
              <BodyBg />
            </Hidden>
          </>
        )}
      </SnackbarProvider>
    </>
  )
}

const styles = makeStyles(theme => ({
  directionLtr: {
    flip: false,
  },
  // bodyBg: {
  //   backgroundImage: `url(${bodyBg})`,
  //   backgroundAttachment: "fixed",
  //   backgroundPosition: "center",
  //   backgroundRepeat: "no-repeat",
  //   backgroundSize: "contain",
  //   position: "relative",
  // },
  parallaxContainer: {
    position: "relative",
    width: "100%",
    height: "100vh",
    maxHeight: "100%",
    padding: 0,
    margin: 0,
  },
  box: {
    position: "absolute",
    width: 200,
    height: 200,
    backgroundColor: "rgba(13,98,189,0.5)",
    top: 130,
    left: "50%",
  },
  fullWidthContainer: {
    maxWidth: "100%",
    padding: 0,
    margin: 0,
  },
  innerContainer: {
    padding: `0 1.0875rem 1.45rem`,
    margin: `0 auto`,
    maxWidth: 1300,
  },
  sliderStyle: {
    marginTop: 36,
    marginBottom: "1rem",
    maxWidth: "100vw",
    "& .slick-arrow": {
      display: "none !important",
    },
  },
  sectionTitle: {
    fontWeight: "bold",
    fontSize: "2rem",
    textAlign: "center",
    textTransform: "uppercase",
  },
  sectionTitleLine: {
    backgroundImage: `url(${titleLine})`,
    width: "145px",
    backgroundPosition: "center",
    backgroundSize: "contain",
    margin: "0 auto",
    padding: 10,
    backgroundRepeat: "no-repeat",
  },
  sectionsPadding: {
    padding: "60px 0 40px",
  },
  teamCarousell: {
    "& .slick-dots li button:before": {
      color: "#00a69c",
      fontSize: 12,
      opacity: 1,
    },
    "& .slick-dots li.slick-active button:before": {
      color: "#29b473",
      fontSize: 18,
    },
    "& .slick-dots": {
      marginBottom: -16,
    },
  },
  footerBg: {
    backgroundColor: "#1a1c20",
    "& .MuiGrid-container": {
      justifyContent: "center",
    },
  },
  footerLogo: {
    maxWidth: 180,
    margin: "10px 0 0",
  },
  footerAddress: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    "& p": {
      color: "#fff",
    },
  },
  heroText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pageLoadingContainer: {
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255)",
    top: "0",
    left: "0",
    zIndex: "9",
  },
  pageLoading: {
    color: "#00a69c",
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  preloader: {
    backgroundColor: "#fff",
  },
  preloaderTextLogo: {
    position: "absolute",
    left: "50%",
    top: "50%",
  },
}))

export default IndexPage
