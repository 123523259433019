import React, { useRef, useEffect, useState } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import {
  Container,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import { Title } from "../blocks"
// import { TeamSlider } from "./teamSlide"
import { gql } from "@apollo/client"
import { useQuery } from "@apollo/client"
import Slider from "react-slick"

import { TeamSliderItem } from "../TeamSliderItem"

gsap.registerPlugin(ScrollTrigger)

function OurTeam() {
  const classes = useStyles()
  const theme = useTheme()
  const containerRef = useRef(null)
  const [teamData, setTeamData] = useState()
  const isTablet = useMediaQuery(
    theme.breakpoints.between("(min-width:600.99px)", "(max-width:900px)")
  )
  const isMobile = useMediaQuery("(max-width:600px)")

  let teamSlideItems
  if (isMobile) {
    teamSlideItems = 1
  } else if (isTablet) {
    teamSlideItems = 2
  } else {
    teamSlideItems = 3
  }

  const team_settings = {
    infinite: true,
    slidesToShow: teamSlideItems,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    swipeToSlide: true,
  }

  useEffect(() => {
    gsap.from(containerRef.current, {
      scrollTrigger: containerRef.current,
      y: 50,
      opacity: 0,
      duration: 1,
      stagger: 0.5,
      // ease: "elastic.out(0.2, 0.1)",
    })
  }, [])

  const APOLLO_QUERY = gql`
    query ExampleQuery($queryTeamContentsOrderby: String) {
      queryTeamContents(orderby: $queryTeamContentsOrderby) {
        id
        data {
          name {
            iv
          }
          position {
            iv
          }
          image {
            iv {
              url
            }
          }
        }
      }
    }
  `

  const { loading, error, data } = useQuery(APOLLO_QUERY, {
    variables: { queryTeamContentsOrderby: "created desc" },
  })

  useEffect(() => {
    if (!loading) {
      setTeamData(
        data &&
          data.queryTeamContents.map(item => ({
            id: item.id,
            image: item.data && item.data.image.iv && item.data.image.iv[0].url,
            name: item.data && item.data.name && item.data.name.iv,
            position: item.data && item.data.position && item.data.position.iv,
          }))
      )
    }
  }, [loading])

  return (
    <Container
      ref={containerRef}
      className={`${classes.sectionTeam} ${classes.innerContainer} ${classes.sectionsPadding}`}
    >
      <Title title={"Our Team"} />
      <Slider
        {...team_settings}
        className={`${classes.directionLtr} ${classes.sliderStyle} ${classes.teamCarousell}`}
      >
        {teamData &&
          teamData.map(item => (
            <TeamSliderItem slideItem={item} key={item.id} />
          ))}
      </Slider>
      {/* // <TeamSlider data={item} key={item.id} /> */}
    </Container>
  )
}

export { OurTeam }

const useStyles = makeStyles(theme => ({
  innerContainer: {
    padding: `0 1.0875rem 1.45rem`,
    margin: `0 auto`,
    maxWidth: 1300,
  },
  sectionsPadding: {
    padding: "60px 0 40px",
  },
  directionLtr: {
    flip: false,
  },
  sliderStyle: {
    marginTop: 36,
    marginBottom: "1rem",
    maxWidth: "100vw",
    "& .slick-arrow": {
      display: "none !important",
    },
  },
  teamCarousell: {
    "& .slick-dots li button:before": {
      color: "#00a69c",
      fontSize: 12,
      opacity: 1,
    },
    "& .slick-dots li.slick-active button:before": {
      color: "#29b473",
      fontSize: 18,
    },
    "& .slick-dots": {
      marginBottom: -16,
    },
  },
}))
