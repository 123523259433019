import React, { useEffect } from "react"
import { Container, Hidden, makeStyles, Grid, Button } from "@material-ui/core"
import { Link } from "react-scroll"

import logo from "../../../static/images/logo500.png"

const Header = ({ setContactFormToggle, contactFormToggle }) => {
  const classes = useStyles()

  // const handleClickFormButton = () => {
  //   // setContactFormToggle(!contactFormToggle)
  //   if (contactFormToggle) {
  //     scrollTo("#form")
  //   }
  // }

  // useEffect(() => {
  //   if (contactFormToggle) {
  //     scrollTo("#form")
  //   }
  // }, [contactFormToggle, handleClickFormButton])

  return (
    <>
      <Hidden mdUp>
        <Container maxWidth={false} className={classes.header}>
          <Container className={classes.containerMobile}>
            <Grid container>
              <Grid item sm={6} sx={6} />
              <Grid item sm={6} sx={6} className={classes.headerSecondSection}>
                <Link to="form" spy={true} smooth={true}>
                  <Button
                    // onClick={() => handleClickFormButton()}
                    variant="outlined"
                    className={classes.buttonMobile}
                  >
                    Contact us
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Hidden>
      <Hidden smDown>
        <Container maxWidth={false} className={classes.header}>
          <Container className={classes.container}>
            <Grid container>
              <Grid item sm={6} sx={6}>
                <img src={logo} alt="logo" className={classes.logo} />
              </Grid>
              <Grid item sm={6} sx={6} className={classes.headerSecondSection}>
                <Link to="form" spy={true} smooth={true}>
                  <Button
                    // onClick={() => handleClickFormButton()}
                    variant="outlined"
                    className={classes.button}
                  >
                    Contact us
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Hidden>
    </>
  )
}
export default Header

const useStyles = makeStyles(theme => ({
  header: {
    position: "absolute",
    zIndex: 2
  },
  fullWidthContainer: {
    maxWidth: "100%",
    padding: 0,
    margin: 0,
  },
  footerBg: {
    backgroundColor: "#1a1c20",
    "& .MuiGrid-container": {
      justifyContent: "center",
    },
  },
  logo: {
    maxWidth: 160,
    // margin: "10px 0 0",
    // backgroundColor: "#1a1c20",
    "& .MuiGrid-container": {
      justifyContent: "center",
    },
  },
  container: {
    maxWidth: "92%",
    margin: "0 auto",
  },
  headerSecondSection: {
    direction: "rtl",
    [theme.breakpoints.up("md")]: {
      marginTop: 24,
    },
  },
  button: {
    color: "#fff",
    borderRadius: 10,
    fontWeight: "bold",
    transition: "0.3s",
    borderColor: "#fff",
    boxShadow: "2px 2px 5px 0px rgb(0 0 0 / 20%)",
    "&:hover": {
      borderRadius: 5,
      transition: "0.3s",
    },
  },
  buttonMobile: {
    fontSize: 12,
    color: "#fff",
    borderWidth: 0,
    borderRadius: 10,
    fontWeight: "bold",
    padding: "12px 24px 10px",
    transition: "0.3s",
    boxShadow: "2px 2px 5px 0px rgb(0 0 0 / 20%)",
    backgroundImage: "linear-gradient(248deg, #00a69c 0%, #29b473 91%)",
    "& .MuiButton-label": {
      lineHeight: 0.7,
    },
    "&:hover": {
      transition: "0.3s",
      backgroundImage: "linear-gradient(248deg, #29b473 0%, #00a69c 91%)",
    },
  },
  containerMobile: {
    maxWidth: "97%",
    margin: "10px auto 0",
    [theme.breakpoints.between("sm", "md")]: {
      direction: "rtl",
      textAlign: "left",
    },
  },
}))
