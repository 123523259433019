import React, { useEffect, useRef } from "react"
import { Container, Grid, Typography, makeStyles } from "@material-ui/core"

import { Tween } from "react-gsap"

import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import aboutBg from "../../../static/images/about.png"
import titleLine from "../../../static/images/title-line.png"

gsap.registerPlugin(ScrollTrigger)

export default function WhoWeAre() {
  const classes = useStyles()

  const imageRef = useRef(null)

  useEffect(() => {
    gsap.from(imageRef.current, {
      scrollTrigger: imageRef.current,
      y: -50,
      delay: 0.8,
      opacity: 0,
      duration: 2,
      stagger: 0.5,
    })
  }, [])

  return (
    <>    
      <Tween
        from={{ x: "0px", y: "0px", opacity: "0" }}
        to={{ x: "0px", y: "0", opacity: "1" }}
        duration={10}
        ease="elastic.out(0.2, 0.1)"
      >
        <Container
          className={`${classes.sectionAbout} ${classes.innerContainer}`}
        >
          <Tween
            from={{ x: "0px", y: "-50px" }}
            to={{ x: "0px", y: "0" }}
            duration={2}
            ease="elastic.out(0.2, 0.1)"
          >
            <Typography className={classes.sectionTitle}>
              What de we do ?
            </Typography>
          </Tween>
          <Tween
            from={{ x: "0px", y: "-50px" }}
            to={{ x: "0px", y: "0" }}
            duration={3}
            delay={0.4}
            ease="elastic.out(0.2, 0.1)"
          >
            <div className={classes.sectionTitleLine}></div>
          </Tween>
          <Grid container className={classes.sectionAboutGrid}>
            <Grid item sm={6} className={classes.sectionAboutList}>
              <ul>
                <Listitem>
                  <Typography className={classes.listItemParageaph}>
                    <b>Native solutions:</b>
                    <span>
                      We provide native and unique solutions on iOS and Android
                      platforms.
                    </span>
                  </Typography>
                </Listitem>
                <Listitem>
                  <Typography className={classes.listItemParageaph}>
                    <b>M-Commerce:</b>
                    <span>
                      Robust, flexible M-Commerce mobile app development for iOS
                      and Android platforms.
                    </span>
                  </Typography>
                </Listitem>
                <Listitem>
                  <Typography className={classes.listItemParageaph}>
                    <b>Featuring mobile marketing methods</b>
                    <span>
                      {" "}
                      such as but not limited to push notifications, deep
                      linking, banners, product listing, advanced search with
                      filtering, and store locator.​
                    </span>
                  </Typography>
                </Listitem>
                <Listitem>
                  <Typography className={classes.listItemParageaph}>
                    <b>Payment integrations:</b>
                    <span>
                      We integrate online payment methods like Credit card,
                      PayPal, and Masterpass to your websites and applications.
                    </span>
                  </Typography>
                </Listitem>
              </ul>
            </Grid>
            <Grid
              item
              sm={6}
              className={classes.sectionAboutBg}
              ref={imageRef}
            ></Grid>
          </Grid>
        </Container>
      </Tween>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  sectionAbout: {
    padding: "50px 0 90px",
  },
  innerContainer: {
    padding: `0 1.0875rem 1.45rem`,
    margin: `0 auto`,
    maxWidth: 1300,
  },
  sectionTitle: {
    fontWeight: "bold",
    fontSize: "2rem",
    textAlign: "center",
    textTransform: "uppercase",
  },
  sectionTitleLine: {
    backgroundImage: `url(${titleLine})`,
    width: "145px",
    backgroundPosition: "center",
    backgroundSize: "contain",
    margin: "0 auto",
    padding: 10,
    backgroundRepeat: "no-repeat",
  },
  sectionAboutGrid: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  sectionAboutBg: {
    backgroundImage: `url(${aboutBg})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    height: "30rem",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      height: "20rem",
      width: "100%",
    },
  },
  sectionAboutList: {
    paddingRight: 15,
    paddingLeft: 15,
  },
  listItemParageaph: {
    display: "inline",
  },
}))

const Listitem = ({ children }) => {
  const revealRef = useRef(null)

  useEffect(() => {
    gsap.from(revealRef.current, {
      scrollTrigger: revealRef.current,
      y: 100,
      delay: 0.4,
      opacity: 0,
      duration: 1,
      stagger: 0.5,
    })
  }, [])

  return <li ref={revealRef}>{children}</li>
}
