import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import { Grid, makeStyles, CircularProgress } from "@material-ui/core"
import { ClientsSliderItem } from "../ClientsSliderItem"
import { gql } from "@apollo/client"
import { useQuery } from "@apollo/client"

function ClintSider() {
  const classes = useStyles()
  const [clintsData, setClintsData] = useState()

  const clients_settings = {
    dots: true,
    speed: 500,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    swipeToSlide: true,
  }

  const APOLLO_QUERY = gql`
    query ExampleQuery($queryTeamContentsOrderby: String) {
      queryClientsContents(orderby: $queryTeamContentsOrderby) {
        id
        data {
          name {
            iv
          }
          image {
            iv {
              url
            }
          }
          position {
            iv
          }
          content {
            iv
          }
        }
      }
    }
  `
  const { loading, error, data } = useQuery(APOLLO_QUERY, {
    variables: { queryTeamContentsOrderby: "created desc" },
  })

  useEffect(() => {
    if (!loading) {
      setClintsData(
        data &&
          data.queryClientsContents.map(item => ({
            id: item.id,
            image: item.data && item.data.image.iv && item.data.image.iv[0].url,
            position: item.data && item.data.position && item.data.position.iv,
            name: item.data && item.data.name && item.data.name.iv,
            content: item.data && item.data.content && item.data.content.iv
          }))
      )
    }
  }, [loading])

  return (
    <Grid container className={classes.sectionPartnersGrid}>
      <Grid item sm={12} className={classes.sectionPartnersGrid}>
        {!loading ? (
          <Slider
            {...clients_settings}
            className={`${classes.directionLtr} ${classes.sliderStyle} ${classes.teamCarousell}`}
          >
            {clintsData &&
              clintsData.map(item => (
                <ClientsSliderItem slideItem={item} key={item.id} />
              ))}
          </Slider>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  )
}

export { ClintSider }

const useStyles = makeStyles(theme => ({
  directionLtr: {
    flip: false,
  },
  sliderStyle: {
    marginTop: 36,
    marginBottom: "1rem",
    maxWidth: "100vw",
    "& .slick-arrow": {
      display: "none !important",
    },
  },
  partnersCarousell: {
    marginBottom: "2rem",
    "& .slick-dots li button:before": {
      color: "#00a69c",
      fontSize: 12,
    },
    "& .slick-dots": {
      marginBottom: -16,
    },
  },
  sectionPartnersGrid: {
    width: "100%"
  }
}))
