import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { Container, Typography, makeStyles } from "@material-ui/core"
import { Title } from "../blocks"
import { ClintSider } from "./clientSlider"

gsap.registerPlugin(ScrollTrigger)

function OurClints() {
  const classes = useStyles()
  const containerRef = useRef(null)

  useEffect(() => {
    gsap.from(containerRef.current, {
      scrollTrigger: containerRef.current,
      y: 50,
      opacity: 0,
      duration: 1,
      stagger: 0.5,
      // ease: "elastic.out(0.2, 0.1)",
    })
  }, [])

  return (
    <Container
      ref={containerRef}
      className={`${classes.sectionClients} ${classes.fullWidthContainer} ${classes.sectionsPadding}`}
    >
      <Title title={"Our Clients Love us"} />
      <ClintSider />
    </Container>
  )
}

export { OurClints }

const useStyles = makeStyles(theme => ({
  fullWidthContainer: {
    maxWidth: "100%",
    padding: 0,
    margin: 0,
  },
  sectionsPadding: {
    padding: "60px 0 40px",
  },
  sectionClients: {
    backgroundColor: "rgba(189, 231, 249, 0.24)",
  },

}))
