import React from "react"
import { makeStyles, Typography, Grid } from "@material-ui/core"

const ClientsSliderItem = ({ slideItem }) => {
  const useStyles = makeStyles(theme => ({
    title: {
      fontSize: 20,
      marginTop: 20,
      color: "#00a69c",
      fontWeight: "bold",
      textAlign: "center",
      // color: "transparent",
      // backgroundClip: "text",
      // WebkitBackgroundClip: "text",
      // background: "-webkit-linear-gradient(left, #29b473 30%, #00a69c 100%)",
    },
    excerpt: {
      color: "#333",
      textAlign: "center",
      fontSize: 14,
      padding: "0 10px"
      // lineHeight: "1.4"
      // fontWeight: "bold",
      // color: "transparent",
      // backgroundClip: "text",
      // WebkitBackgroundClip: "text",
      // background: "-webkit-linear-gradient(left, #29b473 30%, #00a69c 100%)",
    },
    container: {
      // height: "362px",
      display: "flex",
      position: "relative",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      // "&::after": {
      //   top: 10,
      //   width: "90%",
      //   height: "90%",
      //   content: "''",
      //   position: "absolute",
      //   backgroundSize: "contain",
      //   backgroundPosition: "center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundImage: `url(${teamFrame})`,
      // },
    },
    image: {
      width: 200,
      height: 200,
      margin: "0 auto",
      borderRadius: 200,
      backgroundSize: "cover",
      backgroundImage: `url(${slideItem.image})`,
    },
    // featuredImage.node.localFile.childImageSharp.fixed.src
    imageContainer: {
      marginTop: 24,
    },
    psitionTitle: {
      color: "#999",
      textAlign: "center",
      fontSize: 16,
      // lineHeight: "1.4"
    },
  }))

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <div
          /* className={classes.image} */ style={{
            width: 200,
            height: 200,
            margin: "0 auto",
            borderRadius: 200,
            backgroundSize: "cover",
            // backgroundImage: `url(${process.env.NEXT_PUBLIC_API_URL}${slideItem.Image[0].formats.thumbnail.url})`,
            backgroundImage: `url(${slideItem.image})`,
            // featuredImage.node.localFile.childImageSharp.fixed.src
          }}
        ></div>
      </div>
      <Grid item sm={5}>
        <Typography className={classes.title}>{slideItem.name}</Typography>
        <Typography className={classes.excerpt}>
          {slideItem.content}
        </Typography>
        <Typography className={classes.psitionTitle}>
          {slideItem.position}
        </Typography>
      </Grid>
    </div>
  )
}
export { ClientsSliderItem }
