import React, { useRef } from "react"
import "./AnimatedText.css"

// const documentGlobal = typeof document !== `undefined` && document

const AnimatedText = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="intro go"
        viewBox="0 0 400 136"
        x="0px"
        y="0px"
        style={{
          enableBackground: "new 0 0 342.2 259.3",
        }}
        xmlSpace="preserve"
      >
        <text
          textAnchor="start"
          x={100}
          y={30}
          className="text textStroke"
          clipPath="url(#text1)"
        >
          {"Let's Build Your"}
        </text>
        <text
          textAnchor="start"
          x={55}
          y={85}
          className="text textStroke textStrokeLarg"
          clipPath="url(#text2)"
        >
          {"Dream App"}
        </text>
        <text
          textAnchor="start"
          x={135}
          y={125}
          className="text textStroke"
          clipPath="url(#text3)"
        >
          {"Together!"}
        </text>
        <text
          textAnchor="start"
          x={100}
          y={30}
          className="text textStroke textStroke2"
          clipPath="url(#text1)"
        >
          {"Let's Build Your"}
        </text>
        <text
          textAnchor="start"
          x={55}
          y={85}
          className="text textStroke textStroke2 textStrokeLarg"
          clipPath="url(#text2)"
        >
          {"Dream App"}
        </text>
        <text
          textAnchor="start"
          x={135}
          y={125}
          className="text textStroke textStroke2"
          clipPath="url(#text3)"
        >
          {"Together!"}
        </text>
        <defs>
          <clipPath id="text1">
            <text textAnchor="start" x={100} y={30} className="text">
              {"Let's Build Your"}
            </text>
          </clipPath>
          <clipPath id="text2">
            <text
              textAnchor="start"
              x={55}
              y={85}
              className="text textStrokeLarg"
            >
              {"Dream App"}
            </text>
          </clipPath>
          <clipPath id="text3">
            <text textAnchor="start" x={135} y={125} className="text">
              {"Together!"}
            </text>
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
export { AnimatedText }
