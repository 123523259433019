import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { Container, Grid, makeStyles } from "@material-ui/core"
import { Title } from "../blocks"
import ContactForm from "../contactForm"
import axios from "axios"

gsap.registerPlugin(ScrollTrigger)

function ContactUs() {
  const classes = useStyles()
  const containerRef = useRef(null)

  useEffect(() => {
    gsap.from(containerRef.current, {
      scrollTrigger: containerRef.current,
      y: 50,
      opacity: 0,
      duration: 1,
      stagger: 0.5,
      // ease: "elastic.out(0.2, 0.1)",
    })
  }, [])

  return (
    <Container
      ref={containerRef}
      className={`${classes.fullWidthContainer} ${classes.sectionsPadding}`}
    >
      <Container>
        <Grid container>
          <Grid item sm={2} />
          <Grid item sm={8} xs={12}>
            <Title title={"LET’S START A PROJECT TOGETHER"} />
            <div className={classes.contactForm} id="form">
              <ContactForm />
            </div>
            {/* <a href="#top"> Top </a> */}
          </Grid>
          <Grid item sm={2} />
        </Grid>
      </Container>
    </Container>
  )
}

export { ContactUs }

const useStyles = makeStyles(theme => ({
  fullWidthContainer: {
    maxWidth: "100%",
    padding: 0,
    margin: 0,
  },
  sectionsPadding: {
    padding: "60px 0 40px",
  },
  sectionClients: {
    backgroundColor: "rgba(189, 231, 249, 0.24)",
  },
  contactForm: {
    marginTop: 36,
  },
}))
