import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import { Container, makeStyles } from "@material-ui/core"

import { PortfolioSlide } from "./portfolioSlide"
import { Title } from "../blocks"

gsap.registerPlugin(ScrollTrigger)

function OurPartners() {
  const classes = useStyles()
  const containerRef = useRef(null)

  useEffect(() => {
    gsap.from(containerRef.current, {
      scrollTrigger: containerRef.current,
      y: 50,
      // y: -50,
      // delay: 0.4,
      opacity: 0,
      duration: 1,
      stagger: 0.5,
      // ease: "elastic.out(0.2, 0.1)",
    })
  }, [])

  return (
    <Container
      className={`${classes.sectionPartners} ${classes.fullWidthContainer} ${classes.sectionsPadding}`}
      ref={containerRef}
    >
      <Container>
        <Title title={"Our Partners & clients"}/>
        <PortfolioSlide />
      </Container>
    </Container>
  )
}

export { OurPartners }

const useStyles = makeStyles(theme => ({
  fullWidthContainer: {
    maxWidth: "100%",
    padding: 0,
    margin: 0,
  },
  sectionsPadding: {
    padding: "60px 0 40px",
  },
  sectionPartners: {
    backgroundColor: "rgba(189, 231, 249, 0.24)",
  },
  partnersCarousell: {
    marginBottom: "2rem",
    "& .slick-dots li button:before": {
      color: "#00a69c",
      fontSize: 12,
    },
    "& .slick-dots": {
      marginBottom: -16,
    },
  },
}))
