import React, {useRef, useEffect} from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { Typography, makeStyles } from "@material-ui/core"

import titleLine from "../../../static/images/title-line.png"
gsap.registerPlugin(ScrollTrigger)

function Title({ title }) {
  const classes = useStyles()
  const titleRef = useRef(null)
  const titleLineRef = useRef(null)

  useEffect(() => {
    gsap.from(titleRef.current, {
      scrollTrigger: titleRef.current,
      x: -500,
      opacity: 0,
      duration: 2,
      ease: "elastic.out(0.2, 0.1)",
    })

    gsap.from(titleLineRef.current, {
      scrollTrigger: titleLineRef.current,
      x: -500,
      delay: 0.4,
      opacity: 0,
      duration: 0.9,
      ease: "elastic.out(0.2, 0.1)",
    })
  }, [])


  return (
    <>
      <Typography className={classes.title} ref={titleRef}>{title}</Typography>
      <div className={classes.titleLine} ref={titleLineRef}></div>
    </>
  )
}

export { Title }

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "bold",
    fontSize: "2rem",
    textAlign: "center",
    textTransform: "uppercase",
  },
  titleLine: {
    backgroundImage: `url(${titleLine})`,
    width: "145px",
    backgroundPosition: "center",
    backgroundSize: "contain",
    margin: "0 auto",
    padding: 10,
    backgroundRepeat: "no-repeat",
  },
}))
