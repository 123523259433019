import React, { useRef, useEffect, useState } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import logoFull from "../../../static/images/logo-full.png"
import bodyBg from "../../../static/images/body-bg.png"

import { makeStyles } from "@material-ui/core"

gsap.registerPlugin(ScrollTrigger)
const windowGlobal = typeof window !== "undefined" && window

const BodyBg = () => {
  const classes = useStyles()

  // useEffect(() => {
  //   if (windowGlobal) {
  //     windowGlobal.addEventListener("mousemove", handleMouseMove)
  //     const phones1 = document.querySelectorAll("#parallax-container")

  //     let width = windowGlobal.innerWidth
  //     let height = windowGlobal.innerHeight

  //     function handleMouseMove(e) {
  //       let normalizedPosition = e.pageY / (height / 2)
  //       let normalizedPosition2 = e.pageX / (width / 2) - 1

  //       phones1.forEach(span => {
  //         span.style.transform = `translate3d(${5 * normalizedPosition}px, ${
  //           5 * normalizedPosition2
  //         }px , ${28 * normalizedPosition}px )`
  //       })
  //     }
  //   }
  // }, [])

  return (
    <div id="parallax-container" className={classes.parallaxContainer} style={{width: "100%",}}>
      <img
        src={bodyBg}
        alt="main bg"
        className={`${classes.box} ${classes.box3} ${classes.parallax} box box-3 parallax`}
        data-speed-x="5"
        data-speed-y="10"
      />
    </div>
  )
}

export default BodyBg

const useStyles = makeStyles(theme => ({
  parallaxContainer: {
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    textAlign: "center",
    position: "fixed",
    zIndex: -1,
    left: 0,
    top: 0,
  },
  parallax: {
    height: "96%"
  }
}))
