import * as React from "react"

import { Timeline, Tween } from "react-gsap"

const windowGlobal = typeof window !== "undefined" && window

function SvgComponent(props) {
  React.useEffect(() => {
    if (windowGlobal) {
      windowGlobal.addEventListener("mousemove", handleMouseMove)
      const phones1 = document.querySelectorAll("#prefix__XMLID_1318_")
      const phones2 = document.querySelectorAll("#prefix__XMLID_4341_")
      const allphoneitems1 = document.querySelectorAll(
        "#prefix__XMLID_allphone1"
      )
      const allgraphs = document.querySelectorAll("#prefix__XMLID_1321_")
      const shadowsman = document.querySelectorAll("#prefix__XMLID_1406_")
      const shadowswomen = document.querySelectorAll("#prefix__XMLID_473_")

      let width = windowGlobal.innerWidth
      let height = windowGlobal.innerHeight

      function handleMouseMove(e) {
        let normalizedPosition = e.pageY / (height / 2)
        let normalizedPosition2 = e.pageX / (width / 2) - 1

        phones1.forEach(span => {
          span.style.transform = `translate3d(${10 * normalizedPosition}px, ${
            10 * normalizedPosition2
          }px , ${38 * normalizedPosition}px )`
        })
        phones2.forEach(span => {
          span.style.transform = `translate3d(${10 * normalizedPosition}px, ${
            10 * normalizedPosition2
          }px , ${58 * normalizedPosition}px )`
        })
        allphoneitems1.forEach(span => {
          span.style.transform = `translate3d(${5 * normalizedPosition}px, ${
            5 * normalizedPosition2
          }px , ${38 * normalizedPosition}px )`
        })
        allgraphs.forEach(span => {
          span.style.transform = `translate3d(${20 * normalizedPosition}px, ${
            20 * normalizedPosition2
          }px , ${28 * normalizedPosition}px )`
        })
        shadowsman.forEach(span => {
          span.style.transform = `translate3d(${10 * normalizedPosition}px, ${
            10 * normalizedPosition2
          }px , ${38 * normalizedPosition}px )`
        })
        shadowswomen.forEach(span => {
          span.style.transform = `translate3d(${5 * normalizedPosition}px, ${
            5 * normalizedPosition2
          }px , ${28 * normalizedPosition}px )`
        })
      }
    }
  }, [])

  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 1841.2 1823.7"
      xmlSpace="preserve"
      className="homeHero"
    >
      <style>
        {
          ".prefix__st1{opacity:.3;fill:#009345;enable-background:new}.prefix__st2{fill:#16193b}.prefix__st7{fill:#ffa698}.prefix__st9{fill:#ff8d7c}.prefix__st14{fill:#9789e9}.prefix__st15{fill:#ff8978}.prefix__st27{opacity:.5;fill:#3856d8;enable-background:new}.prefix__st29{fill:#303a7d}.prefix__st34{fill:#e9eeff}.prefix__st36{fill:#fff}.prefix__st37{fill:#627afb}.prefix__st46{fill:#161f69}.prefix__st47{fill:#161a40}"
        }
      </style>
      {/* background */}
      <g id="background">
        <linearGradient
          id="prefix__XMLID_3_"
          gradientUnits="userSpaceOnUse"
          x1={849.534}
          y1={687.405}
          x2={1510.687}
          y2={1955.758}
          gradientTransform="matrix(1 0 0 -1 0 1824)"
        >
          <stop offset={0} stopColor="#29b473" />
          <stop offset={1} stopColor="#00a69c" />
        </linearGradient>
        <path
          id="prefix__XMLID_1737_"
          d="M1840.8 1547.2c-192.9 161.6-441.5 258.8-712.9 258.8C514.2 1806 16.7 1308.5 16.7 694.8c0-267.9 94.8-513.7 252.7-705.6h1571.4v1558z"
          opacity={0.74}
          fill="url(#prefix__XMLID_3_)"
        />
      </g>
      {/* man shadow */}
      <Tween
        from={{ x: "-40px", y: "-200px", opacity: "0" }}
        to={{ x: "0px", y: "0", opacity: ".3" }}
        duration={4}
        ease="elastic.out(0.2, 0.1)"
      >
        <path
          id="prefix__XMLID_1406_"
          className="prefix__st1"
          d="M642.9 1367.3l-222.2-126.5c-24.2-11.1-58.9-19.9-83.6-7-22.5 11.7-59.5 35.7-75.9 45-12.4 7.1-55.6 33-28.7 47.6 79.1 43 157.4 91.4 237.8 135.1 15.2 8.3 37.4 11.3 60.8 9.3 23.2-1.9 47.1-8.7 65.8-19.6 13.3-7.8 26.5-15.4 39.4-22.9 17.4-10.2 26.7-22.1 27.6-33.2.9-10.9-6.1-21-21-27.8z"
        />
      </Tween>
      {/* man */}
      <Tween
        from={{ x: "-40px", y: "-200px", opacity: "0" }}
        to={{ x: "0px", y: "0", opacity: "1" }}
        duration={6}
        ease="elastic.out(0.2, 0.1)"
        delay={0.4}
      >
        <g id="prefix__XMLID_1348_">
          <g id="prefix__XMLID_1400_">
            <path
              id="prefix__XMLID_1405_"
              className="prefix__st2"
              d="M423.4 1310.2c-4 11.6-6.6 16.5-6.5 32.6 2.8 8.5 8.9 12 15.7 17.8l4.7 1c11.8 6.3 16.6 28.5 27.7 38.6 7.8 7.1 23.1 11 38.3 13.9 16.2 3.1 31.4-1.5 26.9-14.2-11.2-31.5-57.1-67.1-59-102.3l-47.8 12.6z"
            />
            <path
              id="prefix__XMLID_1404_"
              className="prefix__st2"
              d="M419.5 1197.6c-6.4 11.5-9.1 18.6-8.9 29.2.4 18.1 1 39.7 5.6 67 2.2 13-1.3 19.7-13.7 16.7-9.8-2.4-21.6-20.1-28.7-31.5-13.3-21.4-9.9-38.9-13.1-47.6l-5.5-3.5c-3.8-3.6-12.4-23.9-11.4-30.5 2.5-4 7-10.8 11.2-15.7 14.2-15.2 18-13.6 33.4-21.2 12.4 14.3 18.1 23.3 31.1 37.1z"
            />
            <linearGradient
              id="prefix__XMLID_4_"
              gradientUnits="userSpaceOnUse"
              x1={433.162}
              y1={895.067}
              x2={535.269}
              y2={758.463}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#40457f" />
              <stop offset={1} stopColor="#1e2353" />
            </linearGradient>
            <path
              id="prefix__XMLID_1403_"
              d="M571.5 796.6c.5 3.2-1.2 15.7 0 19.4 15.9 48.6 16.5 150.4 19.7 200.3.2 3.5 0 6.6-.5 9.5-.7 6.3-2.5 12.2-6.7 16.9-48.9 46.6-121 116.5-166.3 163.7-14.9 4.7-39.5-24.9-32.3-47.4 32.6-40.5 55.4-90.5 78.5-109.3 14.1-11.4 30.1-14.4 44.7-28.5-14.3-48.4-37.1-129.4-43.4-170.2l53-24.6 33.3-18 20-11.8z"
              fill="url(#prefix__XMLID_4_)"
            />
            <path
              id="prefix__XMLID_1402_"
              d="M543.4 888.4c.5 3.2-16.2 36.2-16.5 40-3 39.6-5.1 63-5.7 97.7-4 17.8-13.3 61.1-19.8 96.3-28.9 31.3-60.6 59.8-83.7 83.9-14.9 4.7-39.5-24.9-32.3-47.4 32.6-40.5 55.4-90.5 78.5-109.3 14.1-11.4 30.1-14.4 44.7-28.5-14.3-48.4-37.1-129.4-43.4-170.2l53-24.6 22.6-3.2 2.6 65.3z"
              opacity={0.3}
              fill="#1e2353"
            />
            <linearGradient
              id="prefix__XMLID_5_"
              gradientUnits="userSpaceOnUse"
              x1={519.316}
              y1={933.396}
              x2={398.776}
              y2={626.956}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#40457f" />
              <stop offset={1} stopColor="#1e2353" />
            </linearGradient>
            <path
              id="prefix__XMLID_1401_"
              d="M495 1080.2c-3.5 57.2-17.3 177.5-17.8 233.8-19.1 18.1-46.5 10.8-55.1-7.6-3.7-51.9-7.6-137.3-7.1-165.7.5-23.7 2.1-45.7 15.2-71.9-5.8-84.1-34.4-141.6-23.2-200.5 4.9-25.9 20.6-54.5 26.4-75.9 13.5 17.6 25.8 25 63.2 21.9 3.7-.3 40-22.8 50.1-25.4-1 20.3-8 60.6-13.8 81.9-4.1 14.8-14.2 10.2-17.4 26-10.2 50.1-11.5 102.4-16.2 151.5-.1 9.9-1.1 23.8-4.3 31.9z"
              fill="url(#prefix__XMLID_5_)"
            />
          </g>
          <g id="prefix__XMLID_1349_">
            <g id="prefix__XMLID_1389_">
              <g id="prefix__XMLID_1392_">
                <linearGradient
                  id="prefix__XMLID_6_"
                  gradientUnits="userSpaceOnUse"
                  x1={714.75}
                  y1={1252.82}
                  x2={708.553}
                  y2={1244.34}
                  gradientTransform="matrix(1 0 0 -1 0 1824)"
                >
                  <stop offset={0} stopColor="#ff8a78" />
                  <stop offset={1} stopColor="#ffa799" />
                </linearGradient>
                <path
                  id="prefix__XMLID_1399_"
                  d="M715.5 589.8c6.8-7.2 13.3-11.7 21.8-16.7-3.1-5.1-6.8-9.5-9.6-13.2-.5-1.2-1.5-5.7-2.4-6.5-1.9-2.7-4.8-9-6.6-12.7-7 8.4-13.2 14.9-22.3 22.7-3.5 9.6 6 26 19.1 26.4z"
                  fill="url(#prefix__XMLID_6_)"
                />
                <path
                  id="prefix__XMLID_1398_"
                  className="prefix__st7"
                  d="M769.3 538.5l-7.3-21.8-11.8 7.8c1-2.9 3.6-11.1 6.2-19.2 3.3-10.2 7.7-18.7 1-20.8-6-1.9-8.4 5.3-11.8 14.2-4.7 12.7-8.5 20.7-11.7 26.4-4 7.2-10.4 10.9-13.9 14.5-4.6 4.9-7.8 9.8-9.3 14.4-2 6.3-.9 12.1 2.3 16.5 2.8 3.9 6.8 3.5 12.1 5.4 8.8 3.1 12.2 1.5 15.5.2 6.2-2.3 23.3-32.3 28.7-37.6z"
                />
                <linearGradient
                  id="prefix__XMLID_7_"
                  gradientUnits="userSpaceOnUse"
                  x1={748.375}
                  y1={1279.298}
                  x2={737.831}
                  y2={1261.55}
                  gradientTransform="matrix(1 0 0 -1 0 1824)"
                >
                  <stop offset={0} stopColor="#ff8a78" />
                  <stop offset={1} stopColor="#ffa799" />
                </linearGradient>
                <path
                  id="prefix__XMLID_1397_"
                  d="M774.6 544.2L762 516.8l-11.8 7.8c1-2.9 3.6-11.1 6.2-19.2 3.1-9.9 7.4-18.1 1.6-20.5 2.6 5.3-6.6 30-10.6 39.7-3.3 8.2-4.9 14.5-11 22.6-7.4 9.8-10.9 13.6-7.5 19.2 2.8 4.6 6.3 2.4 10.9 7.5l1.3 2.1c12.5-8.5 14.9-23.3 33.5-31.8z"
                  fill="url(#prefix__XMLID_7_)"
                />
                <path
                  id="prefix__XMLID_1396_"
                  className="prefix__st7"
                  d="M710.7 554.4c1.4-4.4 4.1-14.1 8.1-20.6 1.5-2.4 9.8-5.5 20-15.5 8.2 9.7-3.6 14.7-8.9 24.3-2.3 4.1-.5 16.5-9.1 21.8-6.2 3.8-11.6-3.2-10.1-10z"
                />
                <path
                  id="prefix__XMLID_1395_"
                  className="prefix__st9"
                  d="M724.2 539.1c1.5-2.4 16-10.3 14.6-20.7 8.2 9.7-4.8 15.6-10.7 23.5-2.8 3.7 1.3 17.3-7.3 22.6 6.4-13.5 3.2-25.1 3.4-25.4z"
                />
                <path
                  id="prefix__XMLID_1394_"
                  className="prefix__st9"
                  d="M762.8 516.8c.2 0 .3-.7 1.6-.4 3.5.8 11 3.3 15.5 4.5 6.2 1.7 6.2 2.3 7 7.1v9.4c0 6.1-.5 5.5-4.2 11.1-2.1 3.2-4.1 5.1-6.3 5.3-1.4.1-17-2.8-18.5-4.1-.8-.7 8.1-4.6 9.5-13 1.4-7.6-4.7-19.9-4.6-19.9z"
                />
                <path
                  id="prefix__XMLID_1393_"
                  className="prefix__st7"
                  d="M761.9 516.5c1.6-.6 4.4.5 5.4 1.2 1.5 1 2.1 5.9 2.4 10.9.4 7.3 1.5 9.3-2.3 15.5-3.8 6.2-7.1 8.3-11.3 4.8-1.6-1.4.6-4.4 2.3-12.1l-1.3-5.4c-2.5-.5-10.3 4.4-15.9 7.9-2.1.2 2-12.4 6.3-17 4.6-2.1 9.8-4 14.4-5.8z"
                />
              </g>
              <linearGradient
                id="prefix__XMLID_8_"
                gradientUnits="userSpaceOnUse"
                x1={594.801}
                y1={1293.399}
                x2={639.808}
                y2={1175.998}
                gradientTransform="matrix(1 0 0 -1 0 1824)"
              >
                <stop offset={0} stopColor="#a77ef7" />
                <stop offset={1} stopColor="#5e65d6" />
              </linearGradient>
              <path
                id="prefix__XMLID_1391_"
                d="M631.9 677.5c33.4-17.3 53.8-51.2 79.6-79.4 2.8-3.1 3.2-6.8 6.2-9.7 1.9-1.9 3.9-3.7 6-5.5-9.5-7.2-14.9-17.2-16.8-29.9-2.1 2.2-4.8 4.8-7.8 7-3.5 2.5-5 1.4-12.1 5.9-15.3 9.7-64.3 41.4-74.2 53.8-12.1-20.1-23.2-43.6-40.3-68.5-25.6-53-52-49.1-68-36.6-12.4 9.7-17.5 25.1-2 57.8 13.3 28 45.9 71.6 81.4 106.6 6.2 5.2 19.4 10.9 30 7.5 5.5-1.7 13-6.3 18-9z"
                fill="url(#prefix__XMLID_8_)"
              />
              <path
                id="prefix__XMLID_1390_"
                d="M591.1 662.1c6 10.2 16.5 18.9 48.7 10.9-13.7 7.8-21.5 12.8-26.7 13.9-14.1 2.9-22.9-2.6-29.1-7.8-35.5-35-68.1-78.6-81.4-106.6-15.5-32.7-10.4-48 2-57.8 16-12.5 42.4-16.4 68 36.6 18 50.5 2.3 87.5 18.5 110.8z"
                opacity={0.35}
                fill="#3469c0"
              />
            </g>
            <linearGradient
              id="prefix__XMLID_9_"
              gradientUnits="userSpaceOnUse"
              x1={464.518}
              y1={1252.931}
              x2={510.547}
              y2={1017.036}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#a77ef7" />
              <stop offset={1} stopColor="#5e65d6" />
            </linearGradient>
            <path
              id="prefix__XMLID_1388_"
              d="M527.1 509.7c3.8.5 7.3 1.7 10.8 3.6 25.8 14.3 34.8 56.4 38.9 93.3 3.6 32 .9 58.6-.2 87.8-.8 23.4 2.2 45 1.2 67.1-.3 6.5-2.8 13.7-3.5 20.6 0 5-1.8 10-1.7 13.8-12.9 49.2-134.8 53.3-152.6 20 1.2-4.9 2.7-9.9 3.1-14.9-11.5-10.5-4.9-35.9-6.7-53.7-1.4-13.4-3.3-27-7.2-36.7-16.6-40.8-39.7-72.3-44.3-103.7-3.2-21.6 24.4-48.5 60.8-70.3 35.1-20.9 82.7-32.3 101.4-26.9z"
              fill="url(#prefix__XMLID_9_)"
            />
            <linearGradient
              id="prefix__XMLID_10_"
              gradientUnits="userSpaceOnUse"
              x1={395.06}
              y1={1179.777}
              x2={541.742}
              y2={1051.211}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#6d6afe" />
              <stop offset={1} stopColor="#3fb0f7" />
            </linearGradient>
            <path
              id="prefix__XMLID_1387_"
              d="M578.4 653.2c-.4 13.7-1.3 27.2-1.8 41.3-.6 16.6.8 32.3 1.2 47.8-53.2 27.7-87 38.2-122.1 9.8 1.7 30.8-8.1 49.8-4.7 68 53.4 9.2 88.4-.2 120.7-21.7-16.3 46.9-134.4 50.2-151.8 17.5 1.2-4.9 2.7-9.9 3.1-14.9-11.5-10.5-4.9-35.9-6.7-53.7-1.4-13.4-3.3-27-7.2-36.8-16.6-40.8-39.7-72.3-44.3-103.7-1-6.5.9-13.5 5-20.7 29-30 50.3-18.6 141.3 92.6 15.2-3.7 40.9-13 67.3-25.5z"
              opacity={0.35}
              fill="url(#prefix__XMLID_10_)"
            />
            <g id="prefix__XMLID_1371_">
              <g id="prefix__XMLID_1384_">
                <path
                  id="prefix__XMLID_1386_"
                  className="prefix__st14"
                  d="M576.9 607.8c-13 13.1-92.7 89.4-181.1 69.8l.9-6c93.1 20.7 177.2-69.8 177.4-70.1l2.3 2.2.3 3c.2.3.2.7.2 1.1z"
                />
                <path
                  id="prefix__XMLID_1385_"
                  className="prefix__st14"
                  d="M578.4 655.1c-21.8 20.6-89.8 77.2-165.8 66.2-.5-2.3-1.1-4.4-1.8-6.4 80.9 13.2 153.5-54.6 167.7-68.8 0 3 0 6-.1 9z"
                />
              </g>
              <path
                id="prefix__XMLID_1383_"
                className="prefix__st14"
                d="M408.9 709.5l15.7-32.7 21 35.5 18.1-43.2 24.9 33.3 12.9-46.4 26.3 28.4 12-52.8 19.6 29.6 16.5-62 .9 7.5c.1.9.2 1.8.3 2.6L561 670l-19.4-29.3-11.6 51.2-26.6-28.7-13.1 47.3-25.5-34.1-18.5 44-21.3-35.8-14.3 29.8c-.4-1.3-.9-2.6-1.4-3.8-.1-.4-.3-.8-.4-1.1z"
              />
              <g id="prefix__XMLID_1372_">
                <path
                  id="prefix__XMLID_1382_"
                  className="prefix__st14"
                  d="M426 703.8c1.7.1 3.1 1.7 3.1 3.5s-1.4 3.1-3.1 3c-1.7-.1-3.1-1.7-3.1-3.5s1.4-3.2 3.1-3z"
                />
                <path
                  id="prefix__XMLID_1381_"
                  className="prefix__st14"
                  d="M444.7 684.8c1.7.1 3.1 1.7 3.1 3.5s-1.4 3.1-3.1 3c-1.7-.1-3.1-1.7-3.1-3.5s1.4-3.2 3.1-3z"
                />
                <path
                  id="prefix__XMLID_1380_"
                  className="prefix__st14"
                  d="M466.6 698.8c1.7.1 3.1 1.7 3.1 3.5s-1.4 3.1-3.1 3-3.1-1.7-3.1-3.5 1.4-3.1 3.1-3z"
                />
                <path
                  id="prefix__XMLID_1379_"
                  className="prefix__st14"
                  d="M485.1 674.3c1.7.1 3.1 1.7 3.1 3.5s-1.4 3.1-3.1 3-3.1-1.7-3.1-3.5 1.4-3.1 3.1-3z"
                />
                <path
                  id="prefix__XMLID_1378_"
                  className="prefix__st14"
                  d="M508.3 683.5c1.7.1 3.1 1.7 3.1 3.5s-1.4 3.1-3.1 3c-1.7-.1-3.1-1.7-3.1-3.5s1.3-3.1 3.1-3z"
                />
                <path
                  id="prefix__XMLID_1377_"
                  className="prefix__st14"
                  d="M522.1 656.4c1.7.1 3.1 1.7 3.1 3.5s-1.4 3.1-3.1 3-3.1-1.7-3.1-3.5c0-1.7 1.4-3.1 3.1-3z"
                />
                <path
                  id="prefix__XMLID_1376_"
                  className="prefix__st14"
                  d="M545.5 662.2c1.7.1 3.1 1.7 3.1 3.5s-1.4 3.1-3.1 3c-1.7-.1-3.1-1.7-3.1-3.5s1.4-3.1 3.1-3z"
                />
                <path
                  id="prefix__XMLID_1375_"
                  className="prefix__st14"
                  d="M557.9 632.8c1.7.1 3.1 1.7 3.1 3.5s-1.4 3.1-3.1 3c-1.7-.1-3.1-1.7-3.1-3.5s1.4-3.1 3.1-3z"
                />
                <path
                  id="prefix__XMLID_1374_"
                  className="prefix__st14"
                  d="M577.8 635.9c.3 0 .5.1.8.2v6.3c-.3 0-.5.1-.8 0-1.7-.1-3.1-1.7-3.1-3.5s1.4-3.1 3.1-3z"
                />
                <path
                  id="prefix__XMLID_1373_"
                  className="prefix__st14"
                  d="M405.3 685.4c1.7.1 3.1 1.7 3.1 3.5s-1.4 3.1-3.1 3c-1.7-.1-3.1-1.7-3.1-3.5s1.3-3.1 3.1-3z"
                />
              </g>
            </g>
            <g id="prefix__XMLID_1365_">
              <path
                id="prefix__XMLID_1370_"
                className="prefix__st15"
                d="M687.3 650.3c-.9 5.6-2.2 7.7-4 9.7-4.3 4.6-5.3 6.4-9.7 9.7-.3.3-16 21.4-14.2 8.7.8-5.5 3.1-10.9 4.7-16.9l10.7-27.1c9.7.8 9.7 1.2 14.3-.2 6.3-1.8 8.3-1.3 14.2-3.2 10.8-3.5 13.8 7.6 6.3 11.8-6 3.3-17 3.1-22.3 7.5z"
              />
              <path
                id="prefix__XMLID_1369_"
                className="prefix__st7"
                d="M678.2 611c-8.5 6.8-15.1 16.7-21.9 25.6-11.2 14.5-21.3 29.6-36.7 37.2l-1 29.4c10.2-8 24.3-12.9 32.7-15.9 2.7-1.6 5.3.1 8.3.1 7.6 0 11.2-8.4 12.9-14.3 1.7.2 4.8 0 8.4.4 9 1 17.8 2.7 18.4-2 .6-4.6-5.5-7-16.1-9.2-6.2-1.2-12.1-2.1-11.2-5.7 1-3.5 13.9-3 20.6-2.6 2.5.7 16.1 4.4 19.5 3.9 3-.5 6.9-3 5.5-6.7-1.3-3.2-8.5-6.3-15.6-8.2-5-1.3-11.7-3-21.3-3.5-2.9-.2-3-1.6-.3-3.5 1.4-1 6.4-1.7 7.2-1.8 6.4-.5 19-1.4 24.3-2.1 5.5-1.3 6.8-2.9 6.7-5.4.8-8.3-17.1-5.9-32.5-4.7-3.7 0 0-3.5.1-3.5 4.6-2.4 8.5-3.4 14.4-6.2 6.8-3.2 9.2-5.3 8.5-8-.8-3-9.3-1.7-17.8.8-5.1 1.5-9.9 3.4-13.1 5.9z"
              />
              <path
                id="prefix__XMLID_1368_"
                className="prefix__st15"
                d="M686.7 622c-4.5-.5.8-2.9.9-3 4.6-2.4 8.2-3.1 14-5.9 6.4-3 7.8-6 7.5-8.4-3.1 3-9.2 5.6-15 7.5-5.6 1.8-8.5 2.9-10.5 4.6-2.6 2.1-2.8 3.4-6.2 8.8 2.1-1.8 5.9-3.2 9.3-3.6z"
              />
              <path
                id="prefix__XMLID_1367_"
                className="prefix__st15"
                d="M617.9 691.1l-7 15.5c6.1-4.1 20.5-9.9 29.8-14.1 5.1-2.3 9.2-4.4 10.9-5.4 2.3-1.3 3.5.8 7.2.2-1.8-4.4-3.1-11.5.9-14 2.2-1.3 5.1-2.9 10.2-2.5 1.7.2 4.8 0 8.4.4 5.8.7 15 3.6 19.3 2.2.8-.2 1.2-.6 1.5-1.3-3.5 2-15-1.8-20.3-2.8-5.2-.9-11.4-1-16.4-.6-8.7.6-14.7 2-21.6 7.9-7.2 6.4-21.6 13.1-22.9 14.5z"
              />
              <linearGradient
                id="prefix__XMLID_11_"
                gradientUnits="userSpaceOnUse"
                x1={506.207}
                y1={1192.305}
                x2={482.159}
                y2={1053.865}
                gradientTransform="matrix(1 0 0 -1 0 1824)"
              >
                <stop offset={0} stopColor="#a77ef7" />
                <stop offset={1} stopColor="#5e65d6" />
              </linearGradient>
              <path
                id="prefix__XMLID_1366_"
                d="M516.3 751.6c35.9-9.9 96.9-38.2 110.3-48.4 3.1-2.3 5.3-5.7 8.6-7.4 1.8-.9 5.1-2.5 6.9-3.3-5-5.1-10.9-23.7-9.9-28.3-1.7.6-5.1 2.3-7.2 2.9-4.2 1.3-8.9.9-14.3 1.7-36 5.5-81.2 12.2-114.4 25.5-8.8-16.1-24.1-53.2-45.5-82.8-12.5-17.3-26.1-29.9-39.3-36.1-17.9-8.4-29.6-3.3-37.1 4.2-12.6 12.7-12 37.8-3.1 55.1 16.2 31.5 40.9 60.8 77.7 92.3 7.9 6.8 16.1 11.7 27.1 19.8 7.3 7.2 11.1 10.8 24.5 8.4 3.6-.6 8-1.9 14.3-3.3.6.1 1-.1 1.4-.3z"
                fill="url(#prefix__XMLID_11_)"
              />
            </g>
            <g id="prefix__XMLID_1350_">
              <g id="prefix__XMLID_1356_">
                <g id="prefix__XMLID_1358_">
                  <path
                    id="prefix__XMLID_1364_"
                    className="prefix__st7"
                    d="M509.5 533.6c.4-2 .8-3.9 1.3-6 1.2-6-.4-17.7 1.3-31.5L459.8 474c-.9 29.3-5.7 35.4-11.1 59.3.6 23.5 58.9 34 60.1 6.4.2-2.1.3-4.1.7-6.1z"
                  />
                  <linearGradient
                    id="prefix__XMLID_12_"
                    gradientUnits="userSpaceOnUse"
                    x1={488.351}
                    y1={1321.681}
                    x2={474.801}
                    y2={1306.482}
                    gradientTransform="matrix(1 0 0 -1 0 1824)"
                  >
                    <stop offset={0} stopColor="#ff8a78" />
                    <stop offset={1} stopColor="#ffa799" />
                  </linearGradient>
                  <path
                    id="prefix__XMLID_1363_"
                    d="M509.5 533.6c.4-2 .8-3.9 1.3-6 1.2-6-.4-17.7 1.3-31.5L459.8 474c-.2 6.7-.6 12.1-1.2 16.9-1.9 19.4 16.8 53.2 50.3 48.8.1-2.1.2-4.1.6-6.1z"
                    fill="url(#prefix__XMLID_12_)"
                  />
                  <path
                    id="prefix__XMLID_1362_"
                    className="prefix__st7"
                    d="M512.5 532.3c-6.4-4.2-23.9-14.9-29.7-19.1-12.5-9.2-11-21-14.7-39.4-11.7-8.4-15.7-15-16.7-22.6-2.5-17.9 7.7-37.1 21.3-47.6 11.9-9.2 25.6-11.8 41.1-8.4 30 6.6 42.3 29.2 43.9 55.1.1 2.2-.2 4.2-.8 6.4-1.6 5.2-2 6-2.8 9.6-1.6 7.7 3.6 10.5 1.5 20-2.8 13-13.6 38.3-18 42.6-3.4 3.4-18.5 4.5-25.1 3.4z"
                  />
                  <linearGradient
                    id="prefix__XMLID_13_"
                    gradientUnits="userSpaceOnUse"
                    x1={533.969}
                    y1={1366.369}
                    x2={517.162}
                    y2={1324.887}
                    gradientTransform="matrix(1 0 0 -1 0 1824)"
                  >
                    <stop offset={0} stopColor="#ffb89e" />
                    <stop offset={1} stopColor="#ffa799" />
                  </linearGradient>
                  <path
                    id="prefix__XMLID_1361_"
                    d="M556.3 439.1c.7 3.7 1.2 7.4 1.4 11.3.1 2.2-.2 4.2-.8 6.4-1.6 5.2-2 6-2.8 9.6-1.6 7.7 3.6 10.5 1.5 20-1.4 6.3-4.6 15.6-8.1 23.9-19.3 14.7-36.2-3.3-38.7-6.6-12.1-15.6-13-38.9-3.9-60.7 13.9 4.8 36.7 3.9 51.4-3.9z"
                    fill="url(#prefix__XMLID_13_)"
                  />
                  <path
                    id="prefix__XMLID_1360_"
                    d="M443 442.8c.8 9.9 8.5 35.4 14.4 44.8 6.1 9.8 3.4-2.2 6.6-8.9 2.5-5.1 10.4-5.7 12.9-11 1.2 4.5 2.8 8.9 5.7 10.5 1.9 1 5.6 2.4 5.7-2.9.5-18.4 9.2-19.7 6.5-30.7-2.1-8.3-1.6-11.8.5-14.8 6 .8 9.9 7 16.9 10 4.9 2.1 26.5 4.4 41.8-4 14.8-8.1 16.2-26.1 3.8-37.7-6.9-6.5-14.7-8.1-25.6-15.4-20.7-13.9-46.6-9-60.6 6.4-27.4 5.6-30.3 31.6-28.6 53.7z"
                    fill="#562e65"
                  />
                  <path
                    id="prefix__XMLID_1359_"
                    className="prefix__st7"
                    d="M472 458.3c-11.3-5.1-12.7 8.4-11.7 16.7.7 5.8 5.4 5.8 6.6 10.4 1.5 5.5 5.4 8.4 8.9 8.6 7.1.5 3.8-10.5 3.3-14.8 1.6-6.7.1-17.6-7.1-20.9z"
                  />
                </g>
                <linearGradient
                  id="prefix__XMLID_14_"
                  gradientUnits="userSpaceOnUse"
                  x1={542.328}
                  y1={1397.821}
                  x2={529.939}
                  y2={1409.699}
                  gradientTransform="matrix(1 0 0 -1 0 1824)"
                >
                  <stop offset={0} stopColor="#8a4791" />
                  <stop offset={1} stopColor="#562e65" />
                </linearGradient>
                <path
                  id="prefix__XMLID_1357_"
                  d="M503 420.9c-1.1 7.7 8.8 16.7 22.7 17.5 12.9.7 30.3-2.3 36.8-18 2.3-5.5-.5-15.3-6.8-19.3-4.3-2.8-9.8-2.4-15.4.1-9.5 4.2-17.5 14.8-24.3 17.4-6.5 2.4-12.9 1.5-13 2.3z"
                  fill="url(#prefix__XMLID_14_)"
                />
              </g>
              <g id="prefix__XMLID_1351_">
                <linearGradient
                  id="prefix__XMLID_15_"
                  gradientUnits="userSpaceOnUse"
                  x1={569.41}
                  y1={1370.946}
                  x2={495.942}
                  y2={1337.337}
                  gradientTransform="matrix(1 0 0 -1 0 1824)"
                >
                  <stop offset={0} stopColor="#3e4795" />
                  <stop offset={1} stopColor="#273088" />
                </linearGradient>
                <path
                  id="prefix__XMLID_1355_"
                  d="M556.5 438.3c4.8 1.4 9.1 1.9 15 5.6.7.5 4.9 2.7 8.2 8.4.7 1.4 1.1 2.7 1.1 4.2.2 5.6-1.5 15.6-4.6 24.1-3.8 10.5-7.4 14.4-17.1 19.3-8.4 4.2-27.8 11.1-37.5 12-2.5.2-4.8.2-7-.1-12.8-2-19-12.5-22.9-23.6-2.4-6.8-9.2-13-12.5-19.8-1.3-4.4-2-6.7-7.3-10.1.5-1.1 3-4.6 5.2-4.8 4.9-.3 13.3 2 15.2 2.3 8.5 1.2 16.2 2.3 29.6-.9 7.7-1.9 29.7-6.6 32.8-10.3 1.7-2.1 2.1-2.6 1.8-6.3z"
                  fill="url(#prefix__XMLID_15_)"
                />
                <linearGradient
                  id="prefix__XMLID_16_"
                  gradientUnits="userSpaceOnUse"
                  x1={536.047}
                  y1={1345.245}
                  x2={534.289}
                  y2={1366.802}
                  gradientTransform="matrix(1 0 0 -1 0 1824)"
                >
                  <stop offset={0} stopColor="#1c8ef4" />
                  <stop offset={1} stopColor="#00cfdd" />
                </linearGradient>
                <path
                  id="prefix__XMLID_1354_"
                  d="M574.5 450.7c-.9 3.4-5.2 5.6-10.6 8-12.4 5.5-25.5 9.8-38.6 11.6-6.1.8-12.6.5-16.9-.7-5.9-1.8-8.8-4.8-13.4-4.4-8.7.8-5.6 16.7-3.3 23.2-1.9-6.9-2.6-14.1 0-15.8 1.1-.7 2.8-.8 4.1.4 3.1 2.8 5.5 5.1 8.8 6.6 6.7 3.1 14.6 3.4 23.5 1.6 11.3-2.3 28.2-7.8 39-12.9 4.9-2.3 14.8-6.8 13.5-13.6-.7-3.6-4.8-8.2-10.2-11.3-.1-.1 5.1 3.5 4.1 7.3z"
                  fill="url(#prefix__XMLID_16_)"
                />
                <path
                  id="prefix__XMLID_1353_"
                  d="M574.5 450.7c-.9 3.4-5.2 5.6-10.6 8-12.4 5.5-25.5 9.8-38.6 11.6-6.1.8-12.6.5-16.9-.7-5.9-1.8-8.8-4.8-13.4-4.4-4.5.4-5.8 4.8-5.8 9.9.2-4.4 1.8-8.7 5.6-9.1 3.5-.4 5.9 1.8 9.6 3.5 2 .9 5.1 1.8 7.3 2.1 4.1.6 9.3.7 13.9.1 13.1-1.8 26.3-6.1 38.6-11.6 5.4-2.4 10.3-4.9 11.2-8.3.5-1.8 0-4.4-2.5-6.7-.8-.6-1.7-1.2-2.7-1.7.1-.1 5.3 3.5 4.3 7.3z"
                  fill="#00cedc"
                />
                <linearGradient
                  id="prefix__XMLID_17_"
                  gradientUnits="userSpaceOnUse"
                  x1={546.781}
                  y1={1338.214}
                  x2={549.531}
                  y2={1331.274}
                  gradientTransform="matrix(1 0 0 -1 0 1824)"
                >
                  <stop offset={0} stopColor="#1c8ef4" />
                  <stop offset={1} stopColor="#00cfdd" />
                </linearGradient>
                <path
                  id="prefix__XMLID_1352_"
                  d="M567.2 476.4c0-.9-.6-1.4-1.4-1.2-11.9 4.8-23.8 8.7-35.8 12-.8.2-1.4 1.1-1.4 2 0 3.9-.1 6.8-.1 9 0 4.7.1 4.5 4.8 3.6 9.9-2 33.3-9.6 33.7-17.4l.2-8z"
                  fill="url(#prefix__XMLID_17_)"
                />
              </g>
            </g>
          </g>
        </g>
      </Tween>
      {/* phone 2 shadow */}
      <Tween
        from={{ x: "-40px", y: "-200px", opacity: "0" }}
        to={{ x: "0px", y: "0", opacity: ".3" }}
        duration={6}
        ease="elastic.out(0.2, 0.1)"
      >
        <path
          id="prefix__XMLID_1346_"
          className="prefix__st1"
          d="M736.8 1431.6l413.7-236.3c15.3-7 37.9-13.6 53-4.5l38.5 23.5c7.8 4.7 33.2 20.4 16.2 29.7-50.2 27.3-370.6 214.5-421.5 242.2-9.6 5.2-23.7 7.1-38.5 5.9-14.7-1.2-30-5.2-41.7-12.4l-15.4-9.5c-10.9-6.7-16.9-14-17.5-21.1-.6-6.9 3.8-13.3 13.2-17.5z"
        />
      </Tween>
      {/* graph */}
      <Tween
        from={{ x: "200px", y: "-200px", opacity: "0" }}
        to={{ x: "0px", y: "0", opacity: "1" }}
        duration={4}
        ease="elastic.out(0.4, 0.1)"
      >
        <g id="prefix__XMLID_1321_">
          <path
            id="prefix__XMLID_1345_"
            d="M921.2 520l-5.1-2.7c-1.8-.6-4-.3-6.5 1.2-60.9 34.9-383 221.2-443.9 256.1-5.7 3.3-10.4 11.4-10.4 18l-.3 241.2c0 3 1 5.2 2.6 6.4 1.9 1.4 5.9 4.5 9 2.7 60.9-35 381.9-224.3 442.8-259.2 5.8-3.3 10.4-11.4 10.4-18l1.4-245.7z"
            fill="#f2f4ff"
          />
          <linearGradient
            id="prefix__XMLID_18_"
            gradientUnits="userSpaceOnUse"
            x1={702.09}
            y1={959.954}
            x2={678.173}
            y2={1152.333}
            gradientTransform="matrix(1 0 0 -1 0 1824)"
          >
            <stop offset={0} stopColor="#6d6afe" />
            <stop offset={1} stopColor="#3fb0f7" />
          </linearGradient>
          <path
            id="prefix__XMLID_1344_"
            d="M924.3 526.7c0-6.6-4.7-9.3-10.4-6-60.9 35-383 221.2-443.9 256.1-5.7 3.3-10.4 11.4-10.4 18l-.3 241.2c0 6.6 4.7 9.3 10.4 5.9 60.9-35 383-221.2 443.9-256.1 5.8-3.3 10.4-11.4 10.4-18l.3-241.1z"
            fill="url(#prefix__XMLID_18_)"
          />
          <g id="prefix__XMLID_1322_">
            <path
              id="prefix__XMLID_1325_"
              className="prefix__st27"
              d="M492.2 866.9c1.7-1.3 3.2-.8 3.2 1.2s-1.5 4.8-3.2 6.1c-1.8 1.4-3.3.8-3.3-1.2s1.4-4.7 3.3-6.1zm402.7-70.3l-.5-133.8 3.1-2.3.5 134.3-3.1 1.8zm1-145.2c1.7-1.3 3.2-.8 3.2 1.2s-1.5 4.7-3.2 6c-1.8 1.4-3.3.8-3.3-1.1 0-2 1.5-4.7 3.3-6.1zm-51.4 174.3l-.5-115 3.1-2.3.5 115.6-3.1 1.7zm1-126.4c1.8-1.3 3.2-.8 3.3 1.2 0 2-1.5 4.7-3.2 6.1-1.8 1.4-3.3.8-3.3-1.2s1.4-4.8 3.2-6.1zM794 854.8l-.6-155.3 3.1-2.3.6 155.8-3.1 1.8zm.9-166.6c1.8-1.4 3.3-.8 3.3 1.2s-1.5 4.7-3.3 6.1c-1.7 1.3-3.2.8-3.2-1.2s1.4-4.8 3.2-6.1zM743.5 884l-.8-195.5 3-2.3.8 196-3 1.8zm.8-206.9c1.8-1.4 3.2-.8 3.2 1.3 0 2-1.4 4.7-3.2 6-1.8 1.4-3.2.8-3.3-1.2 0-2 1.4-4.7 3.3-6.1zm-51.3 236l-.8-196.4 3.1-2.3.8 197-3.1 1.7zm.8-207.8c1.8-1.3 3.3-.8 3.3 1.2s-1.5 4.7-3.2 6.1c-1.8 1.4-3.3.8-3.3-1.2-.1-2 1.4-4.7 3.2-6.1zm-51.2 237l-.7-167.9 3.1-2.3.7 168.5-3.1 1.7zm.9-179.3c1.7-1.3 3.2-.8 3.2 1.2s-1.5 4.7-3.2 6c-1.8 1.4-3.3.8-3.3-1.1 0-2 1.4-4.7 3.3-6.1zm-51.4 208.4l-.5-129.5 3.1-2.3.5 130.1-3.1 1.7zm1-140.9c1.8-1.4 3.3-.8 3.3 1.2s-1.5 4.7-3.3 6.1c-1.8 1.3-3.2.8-3.3-1.2.1-2 1.5-4.8 3.3-6.1zm-51.4 170l-.4-111.1 3-2.3.4 111.7-3 1.7zm1.1-122.5c1.8-1.3 3.2-.8 3.2 1.2s-1.4 4.7-3.2 6.1c-1.8 1.4-3.3.8-3.3-1.2s1.5-4.7 3.3-6.1zm-51.6 151.6l-.6-151.3 3.1-2.3.6 151.9-3.1 1.7z"
            />
            <path
              id="prefix__XMLID_1324_"
              className="prefix__st27"
              d="M471.3 892.2c.6-1.1 1.4-1.9 2.2-2.5 1-.8 2-1 2.7-.6 6.2 3.9 12 7.2 17.4 9.9 5.3 2.8 10.4 4.9 15.2 6.7 22.2 7.8 39.4 4.6 57.9-9.4 18.2-14 37.2-39 62.4-73.7 28.2-38.8 52.2-66.3 74.8-83.4 3.3-2.5 6.7-4.8 10-6.9 25.6-16.1 49.7-18.5 76.8-8.4 2.8 1 5.5 2.1 8.2 3.1 20.8 8.1 40.3 15.7 65-2.9 12.9-9.7 27.1-26.5 43.8-54 .6-1.1 1.4-1.9 2.2-2.5 1-.8 2.1-1 2.8-.6 1.2.8 1 3.5-.5 5.9-18.6 30.8-34.4 49.4-48.5 60.1-26.7 20.1-47.4 11.9-69.6 3.3-2.8-1-5.5-2-8.2-3.1-25.8-9.6-48.7-7.5-72.7 7.5-3 1.9-6 3.9-9 6.2-21.4 16.1-44.3 42.5-71.6 80-26.2 35.9-46 61.9-65.7 77.1-.4.3-.8.6-1.1.9-19.5 14.7-38.3 18.3-61.8 10.1-4.9-1.7-10.1-4-15.6-6.8s-11.3-6.2-17.6-10.1c-1.3-.8-1.1-3.3.5-5.9z"
            />
            <path
              id="prefix__XMLID_1323_"
              d="M477.4 875.6c.6-1.1 1.4-1.9 2.2-2.5 1-.8 2-1 2.7-.6 6.2 4 12 7.4 17.4 10.2 5.3 2.9 10.4 5.1 15.2 6.9 22.1 8.1 39.4 5.2 57.8-8.5 18.1-13.8 37.2-38.5 62.3-72.9 28.2-38.5 52.1-65.7 74.7-82.5 3.3-2.5 6.7-4.7 9.9-6.8 25.6-15.8 49.6-17.8 76.7-7.3 2.8 1 5.5 2.2 8.2 3.3 20.8 8.5 40.2 16.4 64.9-2 12.8-9.5 27.1-26.2 43.7-53.5.6-1 1.4-1.9 2.2-2.5 1-.8 2-1 2.8-.5 1.2.8 1 3.5-.5 5.9-18.5 30.5-34.3 49-48.4 59.5-26.6 19.7-47.3 11.3-69.5 2.2-2.8-1-5.5-2.1-8.2-3.2-25.8-10-48.6-8.3-72.5 6.5-3 1.8-6 3.9-9 6.1-21.3 15.8-44.3 41.9-71.5 79.1-26.1 35.6-45.9 61.3-65.6 76.3-.4.3-.8.6-1.1.8-19.4 14.4-38.2 17.8-61.7 9.2-4.9-1.8-10-4.1-15.6-7-5.5-2.9-11.3-6.4-17.5-10.4-1.4-.7-1.2-3.2.4-5.8z"
              fill="#0ff"
            />
          </g>
        </g>
      </Tween>
      {/* phone 2 */}
      <Tween
        from={{ x: "200px", y: "-200px", opacity: "0" }}
        to={{ x: "0px", y: "0", opacity: "1" }}
        duration={4}
        ease="elastic.out(0.2, 0.1)"
      >
        <g id="prefix__XMLID_1318_">
          <path
            id="prefix__XMLID_1320_"
            className="prefix__st29"
            d="M746 1406c.1 20.1 7.2 34.3 18.7 41l18.2 10.9c62.5-26.7 39.3-343.3 43.1-433.4L806.8 552c16.1-28.2 78.2-52.8 63.7-110.4l183.2-85.7c8.1-5.5 221.8-129.3 183.5-152.5-6.4-3.9-12.8-7.8-19.1-11.5-14.2-8.4-32.2-3.2-45.5 4.4l-364.4 209c-35.8 20.6-63.8 70.5-63.7 110.4L746 1406z"
          />
          <linearGradient
            id="prefix__XMLID_19_"
            gradientUnits="userSpaceOnUse"
            x1={998.786}
            y1={780.365}
            x2={1030.279}
            y2={1355.591}
            gradientTransform="matrix(1 0 0 -1 0 1824)"
          >
            <stop offset={0} stopColor="#6d6afe" />
            <stop offset={1} stopColor="#3fb0f7" />
          </linearGradient>
          <path
            id="prefix__XMLID_1319_"
            d="M1255.8 244.5c-.1-19.7-6.9-33.8-18-40.6-11.7-7.2-28-6.4-46.2 4l-364.4 209c-35.3 20.4-63.8 69.8-63.7 110.4l1.5 890.1c.1 40.5 28.9 56.9 64.1 36.6l364.4-209c35.3-20.4 63.8-69.8 63.7-110.4l-1.4-890.1z"
            fill="url(#prefix__XMLID_19_)"
          />
        </g>
      </Tween>
      {/* phone 1 shadow */}
      <Tween
        from={{ x: "200px", y: "-200px", opacity: "0" }}
        to={{ x: "0px", y: "0", opacity: ".3" }}
        duration={4}
        ease="elastic.out(0.2, 0.2)"
        delay={0.4}
      >
        <path
          id="prefix__XMLID_1316_"
          className="prefix__st1"
          d="M947.2 1555.6l413.7-236.3c15.3-7 37.9-13.6 53-4.5l38.5 23.5c7.8 4.7 33.2 20.4 16.2 29.7-50.2 27.3-370.6 214.5-421.5 242.2-9.6 5.2-23.7 7.1-38.5 5.9-14.7-1.2-30-5.2-41.7-12.4l-15.4-9.5c-10.9-6.7-16.9-14-17.5-21.1-.7-6.8 3.7-13.2 13.2-17.5z"
        />
      </Tween>
      {/* women shadow */}
      <Tween
        from={{ x: "0px", y: "200px", opacity: "0" }}
        to={{ x: "0px", y: "0", opacity: ".3" }}
        duration={6}
        ease="elastic.out(0.2, 0.1)"
        delay={0.6}
      >
        <path
          id="prefix__XMLID_473_"
          className="prefix__st1"
          d="M1390.9 1501.7l180.6-102.9c19.7-9 47.9-16.2 67.9-5.7 18.3 9.5 48.4 29 61.7 36.6 10.1 5.8 45.2 26.8 23.4 38.7-64.3 35-128 74.3-193.3 109.8-12.3 6.7-30.4 9.2-49.4 7.6-18.9-1.6-38.3-7.1-53.5-15.9-10.8-6.3-21.5-12.5-32-18.6-14.2-8.3-21.7-18-22.4-27-.8-8.9 4.9-17 17-22.6z"
        />
      </Tween>
      {/* star */}
      <Timeline
        delay={2}
        target={
          <g id="star">
            <linearGradient
              id="prefix__XMLID_20_"
              gradientUnits="userSpaceOnUse"
              x1={1665.598}
              y1={1471.073}
              x2={1613.036}
              y2={1515.075}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#1c8ef4" />
              <stop offset={1} stopColor="#00cfdd" />
            </linearGradient>
            <path
              id="prefix__XMLID_50_"
              d="M1640.7 258.5l11.6 33.2c0 .1.1.2.2.3.1.1.2.1.3.1l35.1.8c2.1 0 3.9.8 5.3 1.9 1.3 1.1 2.3 2.5 2.8 4.1.5 1.6.6 3.3.1 5-.5 1.7-1.5 3.4-3.2 4.6l-28 21.3c-.1.1-.2.2-.2.3v.4l10.1 33.6c.6 2 .5 3.9-.2 5.6-.6 1.6-1.7 3-3 4s-2.9 1.6-4.7 1.7c-1.8.1-3.7-.4-5.4-1.5l-28.8-20c-.1-.1-.2-.1-.3-.1s-.3 0-.3.1l-28.9 20c-1.7 1.2-3.6 1.6-5.4 1.5-1.7-.1-3.3-.7-4.7-1.7-1.3-1-2.4-2.3-3-4-.6-1.7-.8-3.6-.2-5.6l10.2-33.6v-.4c0-.1-.1-.2-.2-.3l-28-21.3c-1.7-1.3-2.7-2.9-3.2-4.6-.4-1.6-.4-3.4.1-5s1.5-3 2.8-4.1c1.4-1.1 3.2-1.9 5.3-1.9l35.1-.8c.1 0 .2 0 .3-.1.1-.1.2-.2.2-.3l11.6-33.2c.7-2 1.9-3.4 3.4-4.4 1.4-.9 3.1-1.4 4.8-1.4s3.3.5 4.8 1.4c1.6 1 2.9 2.5 3.6 4.4zm3.9 35.9l-11.6-33.2c-.1-.1-.1-.3-.2-.3s-.2-.1-.3-.1-.3 0-.3.1-.2.1-.2.3l-11.6 33.2c-.6 1.7-1.7 3.2-3.1 4.2-1.4 1-3.1 1.6-4.9 1.6l-35.1.8c-.2 0-.3 0-.3.1-.1.1-.2.2-.2.3 0 .1-.1.3 0 .4 0 .1.1.2.2.3l28 21.3c1.4 1.1 2.5 2.6 3 4.2.5 1.7.6 3.5.1 5.2l-10.2 33.6c-.1.1-.1.3 0 .4 0 .1.1.2.2.3.1.1.2.1.3.1.1 0 .2 0 .4-.1l28.9-20c1.5-1 3.2-1.5 4.9-1.5s3.4.5 4.9 1.5l28.8 20c.1.1.3.1.3.1.1 0 .2-.1.3-.1.1-.1.2-.2.2-.3v-.4l-10.1-33.6c-.5-1.7-.5-3.5.1-5.2.5-1.6 1.6-3.1 3-4.2l28-21.3c.1-.1.2-.2.2-.3v-.4c0-.1-.1-.2-.2-.3-.1-.1-.2-.1-.3-.1l-35.1-.8c-1.8 0-3.5-.6-4.9-1.6-1.5-1.1-2.6-2.5-3.2-4.2z"
              opacity={0.9}
              fill="url(#prefix__XMLID_20_)"
            />
          </g>
        }
      >
        <Tween
          from={{ x: "200px", y: "20px", opacity: "0" }}
          to={{ x: "0px", y: "0px", opacity: "1" }}
          duration={2}
          ease="elastic.out(0.2, 0.1)"
        />
        <Tween
          to={{
            rotation: 60,
            transformOrigin: "center",
            repeat: -1,
            ease: "Power1.easeOut",
            yoyo: true,
          }}
          duration={4}
        />
      </Timeline>
      {/* circle */}
      <Timeline
        delay={2}
        target={
          <g id="circle">
            <linearGradient
              id="prefix__XMLID_21_"
              gradientUnits="userSpaceOnUse"
              x1={1266.572}
              y1={198.813}
              x2={1297.679}
              y2={264.095}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#1c8ef4" />
              <stop offset={1} stopColor="#00cfdd" />
            </linearGradient>
            <path
              id="prefix__XMLID_46_"
              d="M1284.4 1596.3c-1 1.7-3.2 2.3-4.9 1.3-1.7-1-2.3-3.2-1.3-4.9.6-1.1.8-2.3.4-3.4-.3-1.1-1-2.1-2.1-2.7-1.1-.6-2.3-.8-3.4-.5-1.1.3-2.1 1-2.7 2.1-1.7 3-2.1 6.4-1.2 9.5.8 3.1 2.9 5.9 5.9 7.6s6.5 2.1 9.6 1.3c3.1-.8 5.9-2.8 7.6-5.9 2.8-4.9 3.4-10.6 2-15.7s-4.7-9.7-9.7-12.5-10.6-3.4-15.7-2.1-9.7 4.7-12.5 9.6c-3.9 6.9-4.7 14.7-2.8 21.8 1.9 7.1 6.6 13.5 13.5 17.4 6.9 4 14.8 4.8 21.8 2.9 7.1-1.9 13.4-6.5 17.4-13.4 5.1-8.8 6.1-18.8 3.6-27.9-2.5-9.1-8.4-17.3-17.3-22.3s-18.9-6.1-28-3.7-17.2 8.3-22.3 17.2c-6.2 10.7-7.4 22.9-4.4 34 3 11.1 10.3 21 21.1 27.2s23 7.4 34.1 4.5c11.1-3 21-10.2 27.2-20.9 1-1.7 3.2-2.3 4.9-1.3 1.7 1 2.3 3.2 1.3 4.9-7.1 12.4-18.7 20.8-31.6 24.2-12.9 3.4-27.1 2-39.5-5.2s-20.9-18.7-24.4-31.5c-3.5-12.9-2.1-27 5.1-39.5 6-10.5 15.8-17.6 26.7-20.5 10.9-2.9 22.8-1.7 33.4 4.4 10.5 6.1 17.6 15.8 20.6 26.7s1.8 22.8-4.3 33.3c-4.9 8.6-12.9 14.3-21.8 16.7-8.9 2.4-18.7 1.4-27.2-3.6-8.6-4.9-14.4-12.9-16.8-21.8-2.4-8.9-1.4-18.6 3.5-27.2 3.8-6.6 10-11.1 16.9-12.9 6.9-1.8 14.4-1.1 21.1 2.8s11.1 10 13 16.8c1.9 6.9 1.1 14.4-2.7 21.1-2.7 4.7-7.1 7.9-12 9.2-4.9 1.3-10.3.8-15-1.9s-7.9-7.1-9.2-11.9c-1.3-4.9-.8-10.3 1.9-15 1.6-2.8 4.2-4.7 7.1-5.4 2.9-.8 6.1-.4 8.8 1.2 2.8 1.6 4.7 4.2 5.4 7.1.8 2.8.5 6-1.1 8.8z"
              opacity={0.9}
              fill="url(#prefix__XMLID_21_)"
            />
          </g>
        }
      >
        <Tween
          from={{ x: "20px", y: "200px", opacity: "0" }}
          to={{ x: "0px", y: "0px", opacity: "1" }}
          duration={2}
          ease="elastic.out(0.2, 0.1)"
        />
        <Tween
          to={{
            rotation: 120,
            transformOrigin: "center",
            repeat: -1,
            ease: "Power1.easeOut",
            yoyo: true,
          }}
          duration={2}
        />
      </Timeline>
      {/* phone 1 */}
      <Tween
        from={{ x: "200px", y: "-200px", opacity: "0" }}
        to={{ x: "0px", y: "0", opacity: "1" }}
        duration={4}
        ease="elastic.out(0.2, 0.1)"
        delay={0.4}
      >
        <g id="prefix__XMLID_4341_">
          <path
            id="prefix__XMLID_4344_"
            className="prefix__st29"
            d="M956.1 1533.6c.1 20.1 7.2 34.3 18.7 41l18.2 10.9c62.5-26.7 39.3-343.3 43.1-433.4L1017 679.7c16.1-28.2 78.2-52.8 63.7-110.4l183.2-85.7c8.1-5.5 221.9-129.3 183.5-152.5-6.4-3.9-12.8-7.8-19.1-11.5-14.2-8.4-32.2-3.2-45.5 4.5l-364.4 209c-35.8 20.6-63.8 70.5-63.7 110.4l1.4 890.1z"
          />
          <path
            id="prefix__XMLID_4343_"
            className="prefix__st34"
            d="M1466 372c-.1-19.7-6.9-33.8-18-40.6-11.7-7.2-28-6.4-46.2 4l-364.4 209c-35.3 20.4-63.8 69.8-63.7 110.4l1.5 890.1c.1 40.5 28.9 56.9 64.1 36.6l364.4-209c35.3-20.4 63.8-69.8 63.7-110.4L1466 372z"
          />
          <linearGradient
            id="prefix__XMLID_22_"
            gradientUnits="userSpaceOnUse"
            x1={1208.702}
            y1={1105.894}
            x2={1240.004}
            y2={1359.993}
            gradientTransform="matrix(1 0 0 -1 0 1824)"
          >
            <stop offset={0} stopColor="#6d6afe" />
            <stop offset={1} stopColor="#3fb0f7" />
          </linearGradient>
          <path
            id="prefix__XMLID_4342_"
            d="M1466 372c-.1-19.7-6.9-33.8-18-40.6-11.7-7.2-28-6.4-46.2 4l-364.4 209c-35.3 20.4-63.8 69.8-63.7 110.4l.4 232.2c4.5-3.4 9.1-6.5 13.8-9.2l478.5-274.4-.4-231.4z"
            fill="url(#prefix__XMLID_22_)"
          />
        </g>
        {/* phone 1 entries */}
        <g id="prefix__XMLID_allphone1">
          <path
            id="prefix__XMLID_4340_"
            className="prefix__st36"
            d="M1413.7 455.7c0-15.4-5.4-26.3-14-31.6-9.1-5.6-21.9-5-36 3.1l-284.1 162.9c-27.5 15.9-49.8 54.4-49.7 86l1.2 272.7c.1 31.6 22.5 44.4 50 28.5l284.1-162.9c27.5-15.9 49.8-54.4 49.7-86l-1.2-272.7z"
          />
          <path
            id="prefix__XMLID_4321_"
            className="prefix__st37"
            d="M1297 675c1-1.5 2.3-2.7 3.7-3.5 1.3-.8 2.4-1 3.4-.8 1 .2 1.6.9 2 2l-10.9 6.3c.5-1.8 1.2-3.1 1.8-4zm-141.1 67.6l-6.3 25.3-6.3-18-5 2.9-4.6 33.8 5-2.9 2.9-21.4 5.8 16.4 4.6-2.6 5.7-23 2.9 18 5-2.9-4.6-28.5-5.1 2.9zm21.3 10.2l-5.3-11.4-5.1 3 7.7 16.9-4 12.4 5.1-3 12.4-38.6-5.1 3-5.7 17.7zm38.4-20.2c-1.1 1.9-2.4 3.3-3.9 4.2-1.6.9-2.9 1-3.9.3-1.1-.6-1.6-2-1.6-4s.5-3.9 1.6-5.8c1-1.8 2.3-3.2 3.9-4.1 1.6-.9 2.9-1 3.9-.4 1 .7 1.6 2 1.6 4s-.6 3.9-1.6 5.8zm3.8-15.7c-1.8-1.2-4.1-1.1-6.7.4-1.2.7-2.3 1.6-3.4 2.8-1 1.1-2 2.4-2.9 4v-2.6l-5 2.9.1 31.4 5-2.9v-10.8c1 .5 1.9.7 3 .6 1-.1 2.1-.4 3.2-1.1 2.6-1.5 4.8-4 6.7-7.3 1.9-3.4 2.8-6.8 2.8-10.2-.1-3.6-1-6-2.8-7.2zm11.9-9.4c-.8 1.1-1.3 2.3-1.8 3.7v-2.9l-4.3 2.5.1 23 5-2.9v-9.3l-.1-1.1c0-3.2.3-5.5.8-7 .6-1.4 1.2-2.3 2-2.8.4-.2.9-.4 1.5-.3l1.5-5.8c-.7 0-1.4.2-2 .5-1.1.6-2 1.4-2.7 2.4zm20.4 4.2c-1 1.8-2.3 3.2-3.9 4.1-1.6.9-2.9 1-3.9.5-1-.7-1.5-2-1.5-4 0-1.9.5-3.8 1.5-5.7s2.3-3.2 3.9-4.1c1.6-.9 2.9-1 4-.4 1 .7 1.5 1.9 1.5 3.9s-.5 3.8-1.6 5.7zm1.3-16c-1.6-.1-3.4.3-5.3 1.4-2.6 1.5-4.9 3.9-6.9 7.1-2.3 3.8-3.5 7.4-3.4 11 0 3.4 1.1 5.6 3.1 6.8 2.1 1.2 4.5.9 7.4-.7 1.9-1.1 3.7-2.7 5.4-4.7 1.6-2 2.9-4.2 3.8-6.6.9-2.4 1.4-4.8 1.4-6.9 0-2.2-.4-3.9-1.4-5.2-1.1-1.4-2.4-2.1-4.1-2.2zm22.9-20.7c-.6-.6-1.5-.8-2.6-.8-1 0-2.3.5-3.8 1.4-1.7 1-3.2 2.2-4.3 3.6-1.2 1.4-2 2.9-2.6 4.5-.6 1.5-.8 3.5-.8 5.8l-1.8 1.1v4.9l1.8-1.1.1 18.1 5-2.9-.1-18.1 3-1.7v-4.9l-3 1.7c0-1.4 0-2.3.2-2.8.2-.7.6-1.4 1-2 .4-.6.9-1 1.4-1.3.6-.3 1.1-.5 1.6-.4.4.1.8.4.9.9l5.3-3c-.2-1.3-.6-2.4-1.3-3zm-3.7 8.6l5-2.9.1 23-5 2.9-.1-23zm9.2-14.2l.1 31.9 5-2.9-.1-31.9-5 2.9zm19.3 16.2c-1.6.9-2.8 1.1-3.9.8-1-.4-1.6-1.4-1.8-2.8l16.1-9.3v-1.1c0-3.7-1-6.2-2.9-7.4-2-1.1-4.6-.8-7.7 1-3 1.7-5.4 4.3-7.4 7.8-1.9 3.5-2.9 6.9-2.9 10.4 0 3.4 1 5.7 2.9 6.8 2 1.1 4.6.8 7.7-1 2-1.2 3.7-2.6 5.2-4.2 1.5-1.7 2.7-3.7 3.8-6l-4.2.2c-1.4 2.1-3 3.7-4.9 4.8z"
          />
          <g id="prefix__XMLID_4312_">
            <linearGradient
              id="prefix__XMLID_23_"
              gradientUnits="userSpaceOnUse"
              x1={1227.646}
              y1={995.555}
              x2={1235.191}
              y2={1056.357}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#6d6afe" />
              <stop offset={1} stopColor="#3fb0f7" />
            </linearGradient>
            <path
              id="prefix__XMLID_4320_"
              d="M1248.8 768.1c0-2.5-.9-4.3-2.3-5.2-1.5-.9-3.6-.8-5.9.5l-19.2 11c-4.5 2.6-8.1 8.9-8.1 14l.2 44.5 18.4-30.8 17 10.5-.1-44.5z"
              fill="url(#prefix__XMLID_23_)"
            />
            <path
              id="prefix__XMLID_4313_"
              className="prefix__st37"
              d="M1256.9 837.1c.6-1 1.3-1.8 2.2-2.3.9-.5 1.7-.6 2.3-.3.6.4.9 1.1.9 2.2s-.3 2.2-.9 3.3c-.6 1.1-1.4 1.9-2.2 2.4-.8.5-1.6.5-2.2.1-.6-.4-.9-1.1-.9-2.2s.2-2.2.8-3.2zm-32.9 23.6c-.6 1.7-1.5 2.8-2.6 3.5-1.2.7-2.2.6-2.8-.4-.8-.9-1.2-3.2-1.2-6.7 0-2.7.1-4.9.5-6.5.3-1.6.8-2.9 1.4-3.8.6-.9 1.3-1.6 2.1-2.1 1.1-.7 2.1-.5 2.8.5.7 1 1.1 3.3 1.1 7-.1 3.7-.5 6.5-1.3 8.5zm1.7-22c-1.3-.3-2.8.1-4.6 1.1-2.7 1.6-4.9 4.1-6.4 7.5-1.4 3.1-2 7.3-2 12.4 0 3.7.4 6.4 1.1 7.9.7 1.6 1.7 2.5 2.9 2.8 1.2.3 2.8-.2 4.6-1.2 1.9-1.1 3.4-2.5 4.6-4.1 1.2-1.7 2.2-3.8 2.9-6.3.7-2.5 1.1-5.6 1.1-9.3 0-3.7-.4-6.3-1.1-8-.8-1.6-1.8-2.6-3.1-2.8zm-30.2 21.6l5-2.9.1 23 4.7-2.7-.1-28-7 4-2.7 6.6zm50.5-21.7c-1.5-.9-3.3-.7-5.5.5-.3.1-.5.3-.8.6-.3.1-.5.4-.8.6l1-5.7 8-4.6v-4.9l-11.4 6.6-2.9 17.3 3.8-1.2c.5-.9 1-1.6 1.5-2.2.5-.6 1-1 1.5-1.3 1-.6 1.8-.6 2.5-.1.7.4 1 1.3 1 2.6s-.4 2.6-1.1 3.9c-.7 1.3-1.6 2.2-2.6 2.8-.7.4-1.4.6-2 .5-.6-.1-1.1-.5-1.5-1.2l-4.9 2.8c.5 2.1 1.5 3.4 2.9 3.9 1.5.5 3.3.2 5.4-1 2.3-1.3 4.3-3.4 5.9-6.2 1.6-2.8 2.4-5.6 2.4-8.3-.2-2.7-.9-4.6-2.4-5.4zm13.2 8.7c2.1-1.2 3.9-3.1 5.4-5.7 1.4-2.5 2.2-5 2.2-7.4 0-2.5-.7-4.2-2.2-5.1-1.5-.8-3.2-.7-5.3.5l4.2-12h-4l-6.2 17.7c-.8 2.3-1.4 4-1.5 5-.3 1-.3 2-.3 2.8 0 2.3.7 3.8 2.2 4.8 1.5.9 3.3.7 5.5-.6z"
            />
          </g>
          <g id="prefix__XMLID_4305_">
            <linearGradient
              id="prefix__XMLID_24_"
              gradientUnits="userSpaceOnUse"
              x1={1111.107}
              y1={923.128}
              x2={1121.557}
              y2={1009.341}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#6d6afe" />
              <stop offset={1} stopColor="#3fb0f7" />
            </linearGradient>
            <path
              id="prefix__XMLID_4311_"
              d="M1129.8 827.1c-5.4 2.9-10.7 8.9-12.8 17.1-2.6-5.6-8.1-5.8-13.5-2.8-7.6 4.1-15.1 14.4-14.7 24.3.2 7.6 4.9 15.5 17.8 21 4.4 1.9 10.4 4 11.6 3.4.1 0 .2-.1.3-.1.1 0 .1-.1.2-.1 1.5-.8 6.9-9.2 11-15.7 12.1-19.1 16.2-31.7 15.9-39.3-.3-10.1-8.2-11.9-15.8-7.8z"
              fill="url(#prefix__XMLID_24_)"
            />
            <path
              id="prefix__XMLID_4306_"
              className="prefix__st37"
              d="M1138.1 911.5c-.7 1.7-1.6 2.8-2.7 3.5-1.2.7-2.1.5-2.8-.4-.7-.9-1.1-3.2-1.1-6.7 0-2.7.1-4.9.4-6.4.3-1.6.8-2.9 1.4-3.8.6-.9 1.3-1.6 2.1-2 1.2-.7 2.1-.5 2.8.5.7 1 1.1 3.3 1.1 7 0 3.5-.4 6.3-1.2 8.3zm-23.1 8.2l-7.6 13.3v2.5l17-9.8v-4.9l-8.8 5.1 2.7-4.9c2.2-3.8 3.7-7 4.5-9.4.8-2.4 1.2-4.4 1.2-6.1 0-1.4-.3-2.6-1-3.5-.7-1-1.6-1.5-2.7-1.6-1.1-.1-2.5.4-3.9 1.2-2.4 1.4-4.4 3.4-5.9 6.1-1.6 2.6-2.3 5.5-2.5 8.6l4.6-2.6c0-1.5.4-2.9 1-4.1.7-1.2 1.5-2.2 2.5-2.7s1.8-.7 2.4-.3c.6.4.9 1 .9 2.2 0 1-.3 2.4-.9 4-.4 1.3-1.7 3.7-3.5 6.9zm-19.3 22.6l4.7-2.7-.1-28-7 4-2.7 6.5 5-2.9.1 23.1zm44.1-52.8c-1.3-.3-2.8.1-4.6 1.1-2.7 1.6-4.9 4.1-6.4 7.5-1.4 3.1-2.1 7.3-2 12.4 0 3.7.4 6.3 1.1 7.9.8 1.6 1.7 2.5 2.9 2.8 1.3.3 2.8-.1 4.7-1.2 1.8-1.1 3.4-2.4 4.5-4.1 1.2-1.7 2.2-3.8 2.9-6.3.7-2.5 1.1-5.6 1.1-9.3 0-3.7-.4-6.3-1.1-8-.8-1.6-1.8-2.6-3.1-2.8z"
            />
          </g>
          <g id="prefix__XMLID_4300_">
            <linearGradient
              id="prefix__XMLID_25_"
              gradientUnits="userSpaceOnUse"
              x1={1331.961}
              y1={1055.617}
              x2={1338.359}
              y2={1121.427}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#6d6afe" />
              <stop offset={1} stopColor="#3fb0f7" />
            </linearGradient>
            <path
              id="prefix__XMLID_4304_"
              d="M1320.2 717.5l30.9-17.6c5.1-3 9.3-.6 9.3 5.3l.1 21.1c0 5.8-3.9 12.7-8.9 15.8l.1 19.4-16.8-9.7-14.5 8.2c-5.1 3-9.3.6-9.3-5.3l-.1-21.1c-.1-6 4.1-13.1 9.2-16.1z"
              fill="url(#prefix__XMLID_25_)"
            />
            <path
              id="prefix__XMLID_4301_"
              className="prefix__st37"
              d="M1320.5 812.4c1.5.5 3.3.2 5.4-1 2.3-1.4 4.3-3.4 5.9-6.2s2.4-5.6 2.4-8.3c0-2.7-.8-4.5-2.2-5.4-1.5-.9-3.3-.7-5.5.6-.2.1-.5.3-.8.5-.3.2-.5.4-.8.6l1-5.7 8-4.6V778l-11.4 6.6-2.9 17.3 3.8-1.2c.5-.9 1-1.6 1.4-2.2.5-.5 1-1 1.5-1.3 1-.6 1.8-.7 2.4-.2.7.4 1 1.3 1 2.5 0 1.4-.4 2.6-1.1 3.9-.7 1.3-1.6 2.2-2.6 2.8-.7.4-1.4.6-2 .5-.6-.1-1.1-.5-1.5-1.1l-4.9 2.8c.5 2.2 1.4 3.5 2.9 4zm17.3-31.7l10.3-5.9-9.2 26.9 3.9-.1 11-32.4v-2.8l-16.1 9.3.1 5z"
            />
          </g>
          <g id="prefix__XMLID_4293_">
            <linearGradient
              id="prefix__XMLID_26_"
              gradientUnits="userSpaceOnUse"
              x1={1141.488}
              y1={679.72}
              x2={1156.392}
              y2={835.543}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#6d6afe" />
              <stop offset={1} stopColor="#3fb0f7" />
            </linearGradient>
            <path
              id="prefix__XMLID_4299_"
              d="M1264.3 931.4c0-9.4-3.3-16.1-8.6-19.3-5.6-3.4-13.3-3-22 1.9l-173.5 99.5c-16.8 9.7-30.4 33.2-30.3 52.5l.7 166.5c.1 19.3 13.7 27.1 30.5 17.4l173.5-99.5c16.8-9.7 30.4-33.2 30.3-52.5l-.6-166.5z"
              fill="url(#prefix__XMLID_26_)"
            />
            <path
              id="prefix__XMLID_4298_"
              className="prefix__st36"
              d="M1414.1 846.9c0-9.4-3.3-16.1-8.6-19.3-5.6-3.4-13.3-3-22 1.9l-70.1 40.6c-16.8 9.7-30.4 33.2-30.3 52.5l.3 302.3c.1 19.3 13.7 27.1 30.5 17.4l70.1-40.6c16.8-9.7 30.4-33.2 30.3-52.5l-.2-302.3z"
            />
            <g id="prefix__XMLID_4295_">
              <path
                id="prefix__XMLID_4297_"
                className="prefix__st36"
                d="M1144.5 1245.3c0-8.2-2.9-14.1-7.5-16.9-4.9-3-11.7-2.7-19.2 1.7l-61.3 35.6c-14.7 8.5-26.6 29.1-26.6 46l.6 62.5c.1 16.9 12 23.7 26.7 15.2l61.3-35.6c14.7-8.5 26.6-29.1 26.6-46l-.6-62.5z"
              />
              <path
                id="prefix__XMLID_4296_"
                className="prefix__st36"
                d="M1264.5 1176.8c0-8.2-2.9-14.1-7.5-16.9-4.9-3-11.7-2.7-19.2 1.7l-61.3 35.6c-14.7 8.5-26.6 29.1-26.6 46l.6 62.5c.1 16.9 12 23.7 26.7 15.2l61.3-35.6c14.7-8.5 26.6-29.1 26.6-46l-.6-62.5z"
              />
            </g>
            <path
              id="prefix__XMLID_4294_"
              className="prefix__st36"
              d="M1414.4 1230.8c0-9.4-3.3-16.1-8.6-19.3-5.6-3.4-13.3-3.1-22 1.9l-323.6 184.9c-16.8 9.7-30.4 33.2-30.3 52.5l.7 85c.1 19.3 13.7 27.1 30.5 17.4l323.6-184.9c16.8-9.7 30.4-33.2 30.3-52.5l-.6-85z"
            />
          </g>
          <g id="prefix__XMLID_4288_">
            <linearGradient
              id="prefix__XMLID_27_"
              gradientUnits="userSpaceOnUse"
              x1={1217.157}
              y1={612.495}
              x2={1196.126}
              y2={550.212}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#1c8ef4" />
              <stop offset={1} stopColor="#00cfdd" />
            </linearGradient>
            <path
              id="prefix__XMLID_4290_"
              d="M1229.7 1224.6l-.2-14c0-8.4-1.6-13.9-4.7-16.7-3.8-3.4-8.8-3.1-15 .6l-13.5 7.6c-6.6 3.4-10.4 7.5-12.5 13.2-2 5.3-2.5 11.8-2.5 20.3v10.1c-2.4 5.3-4.1 11-4.6 16.8-1.7 19.5 10.2 31.8 26.4 27.5 16.3-4.3 30.9-23.6 32.6-43.1 1-9.5-1.4-17.3-6-22.3zm-39.9 8c.1-6.1.5-10.7 1.9-14.3 1.4-3.7 4-6.4 8.6-8.8 4.6-2.3 9.2-5.4 13.8-7.7 2.8-1.6 4.5-2.2 5.1-1.6 1.3 1.1 1.9 4.6 1.9 10.5l.1 8.7c-3.5-1-7.6-1.1-11.9.1-7.1 1.8-13.9 6.6-19.5 13.1z"
              fill="url(#prefix__XMLID_27_)"
            />
            <path
              id="prefix__XMLID_4289_"
              d="M1202.1 1256.8v9.9c0 2.3 1.9 4.2 4.2 4.2s4.2-1.9 4.2-4.2v-13.1c1.4-1.8 2.4-4.2 2.6-6.5.4-4.5-2.4-7.4-6.1-6.4s-7.2 5.5-7.6 10c-.2 2.9.9 5.1 2.7 6.1z"
              fill="#21389b"
            />
          </g>
          <g id="prefix__XMLID_4284_">
            <path
              id="prefix__XMLID_4287_"
              className="prefix__st36"
              d="M1203 1013c0-4.7-3.3-6.5-7.4-4.2l-89.9 51.9c-4.1 2.3-7.3 8-7.3 12.7l.2 78.1c0 4.7 3.3 6.6 7.4 4.2l89.9-51.9c4.1-2.3 7.3-8 7.3-12.7l-.2-78.1z"
            />
            <linearGradient
              id="prefix__XMLID_28_"
              gradientUnits="userSpaceOnUse"
              x1={1157.66}
              y1={727.065}
              x2={1143.964}
              y2={756.477}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#6d6afe" />
              <stop offset={1} stopColor="#3fb0f7" />
            </linearGradient>
            <path
              id="prefix__XMLID_4286_"
              d="M1150.7 1049.8c15.6-9 28.2-1.8 28.3 16.1.1 17.9-12.5 39.7-28.1 48.7-15.6 9-28.2 1.8-28.3-16.1 0-17.9 12.6-39.7 28.1-48.7z"
              fill="url(#prefix__XMLID_28_)"
            />
            <path
              id="prefix__XMLID_4285_"
              className="prefix__st36"
              d="M1140.7 1102.6l.1-32.3 27.9-2.3z"
            />
          </g>
          {/* women */}
          <path
            id="prefix__XMLID_1310_"
            className="prefix__st34"
            d="M1220 698.4c37.4-20.4 70.1-68.4 73-107.3 2.9-38.9-25.2-53.9-62.6-33.6-37.4 20.3-70.1 68.4-72.9 107.3-2.9 39 25.1 54 62.5 33.6z"
          />
          <linearGradient
            id="prefix__XMLID_37_"
            gradientUnits="userSpaceOnUse"
            x1={1217.848}
            y1={1085.052}
            x2={1236.555}
            y2={1264.791}
            gradientTransform="matrix(1 0 0 -1 0 1824)"
          >
            <stop offset={0} stopColor="#6d6afe" />
            <stop offset={1} stopColor="#3fb0f7" />
          </linearGradient>
          <path
            id="prefix__XMLID_1307_"
            d="M1271.3 636c0-4-1.3-6.8-3.4-8.2-2.2-1.5-5.3-1.3-8.7.8l-14.8 9.1c-6.3 8.9-13.9 16.6-21.9 21.5-8.1 5-15.4 6.5-21.1 4.9l-10.5 6.5c-6.6 4.1-12 14-11.9 22.2v12.5c10.1 8.3 25.8 7.8 44.1-3.5 18.8-11.6 36.2-32.2 48.3-55l-.1-10.8zm-48.2 13.9c-15.6 9.7-27.3 2.5-26.1-16 1.2-18.5 14.8-41.3 30.4-51s27.3-2.5 26.1 16c-1.2 18.5-14.8 41.3-30.4 51z"
            fill="url(#prefix__XMLID_37_)"
          />
        </g>
      </Tween>
      {/* women */}
      <Tween
        from={{ x: "0px", y: "200px", opacity: "0" }}
        to={{ x: "0px", y: "0", opacity: "1" }}
        duration={6}
        ease="elastic.out(0.2, 0.1)"
        delay={0.6}
      >
        <g id="prefix__XMLID_56_">
          <g id="prefix__XMLID_61_">
            <g id="prefix__XMLID_299_">
              <linearGradient
                id="prefix__XMLID_29_"
                gradientUnits="userSpaceOnUse"
                x1={1536.07}
                y1={571.057}
                x2={1584.63}
                y2={481.329}
                gradientTransform="matrix(1 0 0 -1 0 1824)"
              >
                <stop offset={0} stopColor="#ff8a78" />
                <stop offset={1} stopColor="#ffa799" />
              </linearGradient>
              <path
                id="prefix__XMLID_310_"
                d="M1588.3 1435.8c11.8-7.2 28-16.7 16.5-37.7-6.2-11.3-4.3-19.5-2.5-32.7 3.5-26 13.2-63.6 13-87.4-.3-27.3-9.8-40-14.7-87.4-8.2-21.3-47.6-26.8-43.9 25.9.9 13 .3 54.8 4.1 80.3 3.3 22 6.5 45.3 8.3 60.6 3.9 33.7-3.6 45.5-6.1 67.6-4.7 40.3-38.4 49.4 25.3 10.8z"
                fill="url(#prefix__XMLID_29_)"
              />
              <g id="prefix__XMLID_301_">
                <path
                  id="prefix__XMLID_309_"
                  className="prefix__st46"
                  d="M1589.7 1389c-5.3.3-12.3 6.9-15.1 10.5-12.8 16.7-14 54.7-22.9 55.3-2.6.2-11.9-10.3-18.6-10.2-2 0-4.1-.2-5.1 1.4-3.5 5.5 18.4 22.7 25 26.8 2.9 3.7 28.2-1.8 31.8-4.1l.2-.2c.2 6.8.2 13.4.5 19.6l3.2 2.3 4-1.5c.8-15.8 7.1-39.1 11.8-54.7 7.8-9.1 9.2-19.9-1.2-38.4-2.9-5.4-9.3-7-13.6-6.8z"
                />
                <path
                  id="prefix__XMLID_304_"
                  className="prefix__st47"
                  d="M1553.6 1473.3c4.7 2.8 27.8-2.4 31.3-4.6l.2-.2c-.3-12.9-1.1-26.2-5.2-37.1-.6.2-1.1.4-1.7.7-7.5 3.1-17.5 35.9-24.6 41.2z"
                />
              </g>
            </g>
            <g id="prefix__XMLID_233_">
              <linearGradient
                id="prefix__XMLID_30_"
                gradientUnits="userSpaceOnUse"
                x1={1545.336}
                y1={389.998}
                x2={1507.575}
                y2={479.013}
                gradientTransform="matrix(1 0 0 -1 0 1824)"
              >
                <stop offset={0} stopColor="#ffb89e" />
                <stop offset={1} stopColor="#ffa799" />
              </linearGradient>
              <path
                id="prefix__XMLID_298_"
                d="M1512.7 1477.2c-2.6 3.9-15.7 6.9-22.3 8.2-12 3.3 22 14.1 31 15.3 19.3 2.7 5.3-24.7 28.8-40.2 10.8-7.2 25.6-16.7 11.2-37.7-7.8-11.3-8.4-19-8.5-32.3-.2-26 5.9-73.3 2.3-97.1-4.1-27.3-12.1-40.5-27.4-78.2-2.3-5.6-2.2-12-3.6-18.1-8.8-39.7-53.7-29.6-52.5-8.6.5 9.2.5 18.1 2.7 25.6.2 10.6 7.5 22.8 10.8 31.4 2.8 13 10.3 50.4 17.8 75.9 11.6 39.7 31.4 123 9.7 155.8z"
                fill="url(#prefix__XMLID_30_)"
              />
              <g id="prefix__XMLID_293_">
                <path
                  id="prefix__XMLID_297_"
                  className="prefix__st46"
                  d="M1560.4 1420.6c10.4 4.2 18 23.2 13.9 39.9-4.9 18.8-5.9 32.8-7.4 54.2l-4.5 1.4-3.5-2.2.1-41.5c-5.2 5.7-12.3 20.8-17.6 29-4.5 6.9-9.6 8.2-20.4 8.6-14.2.6-28-3.9-37.5-10.2-9.6-6.4-10.6-12.6 1.9-14.7 5.5-.9 13-3 15.2-3.4-2.4.6-8.9 4.7-4.9 8 5.7 4.8 22.8 2.4 24.6 1 11-8.7 17.7-25.5 23.3-40.7 5-13.2 8.3-25.4 16.8-29.4z"
                />
                <path
                  id="prefix__XMLID_295_"
                  className="prefix__st47"
                  d="M1559 1472.1c-1.7 3.9-8.8 14.3-14.3 24.4-2.5 4.5-6.4 11.1-11.9 11.9 9.4-9.2 8.2-31.2 26.2-36.3z"
                />
              </g>
            </g>
            <linearGradient
              id="prefix__XMLID_31_"
              gradientUnits="userSpaceOnUse"
              x1={1564.342}
              y1={630.716}
              x2={1534.342}
              y2={1019.763}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#1c8ef4" />
              <stop offset={1} stopColor="#00cfdd" />
            </linearGradient>
            <path
              id="prefix__XMLID_232_"
              d="M1612 982.2c11.6 28.4 15.1 61.2-1.6 94.1-1.1 10.2-2.6 20.4-4.1 30.7-3.3 32.6-.8 61.6-2.8 91.6-18.2 15.7-48.4 37.8-75.6 40.2-2.8.3-5.7.2-8.5-.2-15-1.7-41.2-12.8-45.7-26.3-2.8-24.8-6.4-53.4-8.6-85-3.2-27-3.4-52.8-1.9-78.2 1.6-20 .2-34.9 7.1-54.3 8.7-24.1 20.6-45.6 20.9-72.7.1-15.7-5.9-30.4-7.2-45.9-15.2-22.2-19.5-40.1-14.8-50.7 13.3-30.3 96.7-67.4 128.9-71.1 36.4-4.1 34.8 57.7 21 85.5-17.6 35.6-23.4 55.4-25 70.9-2.2 20.7 6.3 38.3 17.9 71.4z"
              fill="url(#prefix__XMLID_31_)"
            />
            <linearGradient
              id="prefix__XMLID_32_"
              gradientUnits="userSpaceOnUse"
              x1={1492.449}
              y1={852.936}
              x2={1500.683}
              y2={991.479}
              gradientTransform="matrix(1 0 0 -1 0 1824)"
            >
              <stop offset={0} stopColor="#1c8ef4" />
              <stop offset={1} stopColor="#00cfdd" />
            </linearGradient>
            <path
              id="prefix__XMLID_80_"
              d="M1477.7 975.8c6.8-17 13.3-33.8 13.4-53.6.4-49.2-41.9-71.6 8.3-105 9.3 7.9 15.3 29.6 17.3 54.3 2.7 32 3.9 65.7-.1 94.6-3.6 26.3-32.5 22.5-38.9 9.7z"
              opacity={0.3}
              fill="url(#prefix__XMLID_32_)"
            />
            <g id="prefix__XMLID_74_">
              <g id="prefix__XMLID_77_">
                <path
                  id="prefix__XMLID_79_"
                  d="M1357.6 812.8l-9.8-.3c.9-5.2 3.1-4.6.6-12.5l2.2-3.8 7 16.6z"
                  fill="#fb705b"
                />
                <linearGradient
                  id="prefix__XMLID_33_"
                  gradientUnits="userSpaceOnUse"
                  x1={1360.812}
                  y1={1013.462}
                  x2={1368.814}
                  y2={1018.452}
                  gradientTransform="matrix(1 0 0 -1 0 1824)"
                >
                  <stop offset={0} stopColor="#ff8a78" />
                  <stop offset={1} stopColor="#ffa799" />
                </linearGradient>
                <path
                  id="prefix__XMLID_78_"
                  d="M1393.5 824c-5.8-11.8-11-33.1-18.4-42.6-4.6-5.8-10.9-18.8-15.1-25.8-1.7-2.9-4.5-2.1-6.3 0l-.1.2-.6.9-.3.6c-.7 1.4-1.1 2.8-.2 4.8 1.4 3.1 4.9 10.8 7.5 15.6 1.1 2.1 2.3 3.7 2.8 4.3-4.7 1.4-9.5 3.7-12.5 6.3-.2.2-.4.4-.6.5-1.7 1.6-2.6 3.2-4.1 5.2-3.2 4.2-3.8 4-2.2 8.8l4.2 9.9c4.9-5.4 3.2-4.1.8-12l.6-1c1.1 2.3 2.7 5 4.1 8.1 1.8 3.8 1.2 10.7 2.7 14.3l.1.2c.6 1.4 1.4 2.5 2.3 3.6.5.6 1 1.1 1.5 1.6l.5.5.3.2.3.2.3.2.6.5.3.2.3.2.3.2.3.2.3.2.3.2.6.4.6.4.3.2.6.4.3.2.3.2.3.2.3.2.5.4.3.2.3.2.3.2.3.2.3.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.2.3.1.3.1.3.1.3.1.3.1.3.2.6.2.6.1.3.1.3.1.3.2.6.1.3.2.6.1.3.1.3.1.3.1.3.1.3.1.3.1.3.1.3.1.3.1.6.1.3.1.3.1.6.1.3.1.6.2.9.1.6.1.3.1.3.1.6.1.3.3 1.2.1.3.2.9.2.9.5 2.4H1378.5c.4 0 .8-.1 1.2-.1h.8c.4 0 .8-.1 1.2-.2H1382.3l.8-.2h.5l.4-.1h.2l.7-.2.2-.1c1-.3 2-.6 2.9-1h.1l.3-.1h.1l.3-.1h.1l.3-.1h.1c5.2-2.4 10-6.4 14.4-12-4.1-6.8-7-12.5-10.2-18.2z"
                  fill="url(#prefix__XMLID_33_)"
                />
              </g>
              <linearGradient
                id="prefix__XMLID_34_"
                gradientUnits="userSpaceOnUse"
                x1={1381.262}
                y1={949.23}
                x2={1490.648}
                y2={941.709}
                gradientTransform="matrix(1 0 0 -1 0 1824)"
              >
                <stop offset={0} stopColor="#1c8ef4" />
                <stop offset={1} stopColor="#00cfdd" />
              </linearGradient>
              <path
                id="prefix__XMLID_76_"
                d="M1472.4 819.1c-14.2 13.5-31.3 38.7-46.8 61.5-7.6-16.2-16.1-33.4-23.6-44.4-1.4-2.1-2.9-3.1-4.2-4.7l-2.3-4.4c-8.1-8.7-25.5 2.7-24.6 14.1l1.4 4.2c.1 2.1-.3 4.6-.3 6.9-.1 6.3 26.5 70.7 30.8 82 6.5 9.7 13.8 14.5 19.9 14.2 4.6-.2 8.6-3.1 11.7-7.1.8-.8 1.7-1.7 2.5-2.7 14.2-16.8 33.2-39.6 44.1-54.1 7.3-9.8 19.1-24.8 24.7-39.4 11.3-29-14.2-44.2-33.3-26.1z"
                fill="url(#prefix__XMLID_34_)"
              />
            </g>
            <path
              id="prefix__XMLID_72_"
              d="M1595 756.6c61.2-6.9 10.7 109.7 7.5 120.8-5.2 14.1-7.6 24.5-8.5 33.5-2.1 20.5 6.4 38.2 18 71.2 11.6 28.4 15.1 61.2-1.6 94.1-1.1 10.2-2.6 20.4-4.1 30.7-3.3 32.6-.8 61.6-2.8 91.6-8.7 7.5-20.1 16.4-32.6 24-82-110.9 59.2-156.9 1.3-265.5-11.2-20.9-54.2-47.3-70-81-12.4-26.6-2.6-44.5 14.8-62.3 16.6-17 46.9-13.8 53.9-26.5 1-1.8-4-17.5-3.2-19.4 21.5-9.3 14.7-9.8 27.3-11.2z"
              opacity={0.3}
              fill="#0960ac"
            />
            <g id="prefix__XMLID_62_">
              <g id="prefix__XMLID_64_">
                <linearGradient
                  id="prefix__XMLID_35_"
                  gradientUnits="userSpaceOnUse"
                  x1={1546.483}
                  y1={1083.678}
                  x2={1535.651}
                  y2={1051.57}
                  gradientTransform="matrix(1 0 0 -1 0 1824)"
                >
                  <stop offset={0} stopColor="#ff8a78" />
                  <stop offset={1} stopColor="#ffa799" />
                </linearGradient>
                <path
                  id="prefix__XMLID_70_"
                  d="M1583.8 772.4c2.7 11.4-39.1 23.8-53.3 17.6l-.3-2.8c-3.7-17.1-7.3-31.5-9.9-43.5-6.7 2.8-23 3.7-24.4 1.4 44-35.2 95.5-61.1 102.1-43.4-4.4 12.8-15.6 22-21.3 30.4-1.5 2.2-2.7 6.9-2.9 11.5 1.6 9 5.1 17.7 10 28.8z"
                  fill="url(#prefix__XMLID_35_)"
                />
                <path
                  id="prefix__XMLID_66_"
                  className="prefix__st7"
                  d="M1496.2 745.3c3.8 2.5 30.7-6.5 32.9-6.4 21 1 43.5-.7 47.7-6.8 5.6-8.4 16.9-17.5 21.3-30.4 7-20.5 5.6-43.7-13.2-59.8-32.7-28-83.9-7.5-91.2 24.3-3 13 .7 14.1.1 22.8-1.1 16.1-3 10-1.5 35.5.5 9.6 1.4 19.1 3.9 20.8z"
                />
                <path
                  id="prefix__XMLID_65_"
                  d="M1577.8 757.1c-1.2 10.4-30.4 17.7-48.2 13.6-1.9-.4-3.1-.8-3.8-2-2.6 1.4-9.7 11.7-10.5 16.8 15.3 14.8 63.9 6.1 69.8-11.2 1.4-4.1 2.7-12-7.3-17.2z"
                  fill="#14a1ec"
                />
              </g>
              <linearGradient
                id="prefix__XMLID_36_"
                gradientUnits="userSpaceOnUse"
                x1={1544.552}
                y1={1165.377}
                x2={1612.977}
                y2={953.648}
                gradientTransform="matrix(1 0 0 -1 0 1824)"
              >
                <stop offset={0} stopColor="#5a2d68" />
                <stop offset={1} stopColor="#22043d" />
              </linearGradient>
              <path
                id="prefix__XMLID_63_"
                d="M1658.7 722.6c27.9 23.3 43.7 65.4 30.9 89.3-11.5 21.6-39.6 34.3-30.9 48 7.1 11.3 23.9 22.7 4 40.9-10.3 9.4-35.4 34.6-86.8 21.6-50-12.7-102.6-56-60.4-103.2 44.4-49.7 18.4-60.7-7-99.8-13.5-20.7-25.5-61.5-14-76.8 19.8-26.1 32.9-7.3 51.9-16.5 36.3-17.6 66.1-5 83.1 22.5 15.3 24.5 7 55.4 29.2 74z"
                fill="url(#prefix__XMLID_36_)"
              />
            </g>
          </g>
          <g id="prefix__XMLID_57_">
            <path
              id="prefix__XMLID_60_"
              d="M1526.7 712.5c4.1-11.3-3.9-16.6-17.4-24.5-8.3-4.9-13.6-9.8-17-13.3-2.5-2.7-1.5-4.8-1.9-4.7-2.5.8-5.3 3.3-6.2 4.2-4.3 4.3-4.9 9.4-5 12.3-.3 4.4 3.4 9 28.1 16.7 13.4 4.2 20.1 4.5 19.4 9.3z"
              fill="#3e4794"
            />
            <path
              id="prefix__XMLID_59_"
              d="M1494.8 716.2c-10.4-2.4-15.1-3.2-16.7-12.8-2-11.6 0-21.8 3.7-25.5-.3 4.4 4.3 11 29 22.1 10.8 4.9 18.8 6.1 16.1 12.2-2.1 4.5-20 6.7-32.1 4z"
              fill="#1f2873"
            />
            <path
              id="prefix__XMLID_58_"
              d="M1477.6 698.5c4.8 5.3 10.2 4.2 10.5 3.2.8-2-8.1-7.7-10.3-13.5-.5 4.5-.4 5.8-.2 10.3z"
              fill="#548ff9"
            />
          </g>
        </g>
      </Tween>
      {/* moon */}
      <Timeline
        delay={2}
        target={
          <g
            id="prefix__XMLID_5100_"
            transform="translate(0 -270.542)"
            opacity={0.9}
          >
            <g id="prefix__XMLID_5101_">
              <linearGradient
                id="prefix__XMLID_38_"
                gradientUnits="userSpaceOnUse"
                x1={402.795}
                y1={1524.116}
                x2={294.135}
                y2={1539.413}
                gradientTransform="matrix(1 0 0 -1 0 2094.541)"
              >
                <stop offset={0} stopColor="#1c8ef4" />
                <stop offset={1} stopColor="#00cfdd" />
              </linearGradient>
              <path
                id="prefix__XMLID_2_"
                d="M349.1 638.8c-22.3 0-43.3-8.7-59.1-24.5s-24.5-36.8-24.5-59.1c0-30.5 16.6-58.5 43.3-73.2 1.2-.6 2.5-1 3.8-1 1.4 0 2.7.4 3.9 1.1 1.8 1.1 3 2.7 3.6 4.8s.2 4.1-.8 5.9c-6.3 10.5-9.6 22.5-9.6 34.7 0 37.5 30.5 68.1 68.1 68.1 11.4 0 22.8-3 32.8-8.5 2.4-1.3 5.4-1.3 7.7.1 3.7 2.2 4.9 7 2.7 10.6-15 25.2-42.5 40.9-71.9 41zm-37.2-149.4c-23.7 13.4-38.4 38.5-38.4 65.7 0 20.2 7.9 39.2 22.2 53.5 14.3 14.3 33.3 22.2 53.5 22.2 26.3 0 50.9-13.9 64.5-36.3-11 5.9-23.4 9.1-35.9 9.1-42 0-76.1-34.1-76.1-76.1-.1-13.4 3.5-26.5 10.2-38.1zM414 593.7z"
                fill="url(#prefix__XMLID_38_)"
              />
            </g>
          </g>
        }
      >
        <Tween
          from={{ x: "120px", y: "-500px", opacity: "0" }}
          to={{ x: "120px", y: "-370px", opacity: "1" }}
          duration={0.5}
          // ease="back.out(1.7)"
          ease="elastic.out(0.2, 0.1)"
        />
        <Tween
          to={{
            // skewX: 6,
            // skewY: 6,
            rotation: 10,
            transformOrigin: "center",
            repeat: -1,
            ease: "Power1.easeOut",
            yoyo: true,
          }}
          duration={2}
        />
      </Timeline>
    </svg>
  )
}

export { SvgComponent }
