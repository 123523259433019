import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import { makeStyles, Typography } from "@material-ui/core"
import teamFrame from "../../static/images/team-frame.png"
gsap.registerPlugin(ScrollTrigger)

const TeamSliderItem = ({ slideItem }) => {
  const useStyles = makeStyles(theme => ({
    title: {
      fontSize: 20,
      marginTop: 20,
      color: "#333",
      fontWeight: "bold",
      textAlign: "center",
    },
    psitionTitle: {
      color: "#00a69c",
      fontWeight: "bold",
      // color: "transparent",
      // backgroundClip: "text",
      // WebkitBackgroundClip: "text",
      // background: "-webkit-linear-gradient(left, #29b473 30%, #00a69c 100%)",
      "& p": {
        margin: 0,
      },
    },
    container: {
      height: "362px",
      display: "flex",
      position: "relative",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      "&::after": {
        top: 10,
        width: "90%",
        height: "90%",
        content: "''",
        position: "absolute",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${teamFrame})`,
      },
    },
    image: {
      width: 130,
      height: 130,
      margin: "0 auto",
      borderRadius: 200,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundImage: `url(${slideItem.image})`,
      // featuredImage.node.localFile.childImageSharp.fixed.src
      filter: "grayscale(1)",
    },
    imageContainer: {
      marginTop: 24,
    },
  }))

  const classes = useStyles()

  return (
    //   <SlideItem>
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <div className={classes.image}></div>
      </div>
      <Typography className={classes.title}>{slideItem.name}</Typography>
      <Typography component="div" className={classes.psitionTitle}>
        {slideItem.position}
        {/* <div dangerouslySetInnerHTML={{ __html: slideItem.position }} /> */}
      </Typography>
    </div>
    //   </SlideItem>
  )
}
export { TeamSliderItem }

const SlideItem = ({ children }) => {
  const revealRef = useRef(null)

  useEffect(() => {
    gsap.from(revealRef.current, {
      scrollTrigger: revealRef.current,
      y: 100,
      delay: 0.4,
      opacity: 0,
      duration: 1,
      stagger: 0.5,
      rotateX: -60,
    })
  }, [])

  return <div ref={revealRef}>{children}</div>
}
